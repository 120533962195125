import React from "react";
import qs from "qs";

import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useFetch } from "components/utils/fetchHook";
import Link from "components/ExtendLink";
import userImage from "../../files/user.png";
import doctorImage from "../../files/doctor.png";
import BootstrapTable from "react-bootstrap-table-next";

import moment from "moment";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Row
} from "reactstrap";
import { USERS_ROLE } from "../../constants/role.constant";
const roleTypeInfo = USERS_ROLE.filter((item) => item.type == "Staff" || item.type == "Careprovider").map((item) => item.role);
const ViolationDetailQuestion = (props) => {
  const authToken = useSelector((state) => state.user.userDetails.token);

  const urlParams = window.location.search.replace("?", "");
  const convertObject = qs.parse(urlParams);
  const { vuid, client = null } = convertObject;
  if (!authToken) {
    if (client) {
      props.history.push(
        `/${client}/login?redirect=` +
        encodeURIComponent(window.location.pathname + window.location.search)
      );
    } else {
      props.history.push(
        "/login?redirect=" +
        encodeURIComponent(window.location.pathname + window.location.search)
      );
    }
  }

  const goBack = () => {
    props.history.goBack();
  };

  const role = props.role;
  var path = "";
  if(role == 'patient'){
    path = "/patient/violations/"+ vuid;
  }else if(roleTypeInfo.includes(role)){
    path = "/careprovider/violations/"+ vuid;
  }else if (role == 'admin' || role == 'support') {
    path = "/admin/violations/" + vuid;
  } else {
    path = "/client/violations/" + vuid;
  }
  
  const { response, error, isLoading } = useFetch(
    `${path}`
  );

  const responseData = response ? response.data : response;
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;
  const {
    violatedBy,
    createdBy,

    answers,
    reading,
    expectation = []
  } = responseData || [];
  if (!violatedBy) return <div>{response && response.notFound ? response.message : 'Violation does not exist'}</div>;

  if (expectation) {
    var vitalException = (
      <>
        <tr>
          <th className="text-right">Condition:</th>
          <td>{expectation.condition}</td>
        </tr>
        <tr>
          <th className="text-right">Value:</th>
          <td>
            {expectation.valueProperty && (
              <span>
                {expectation.valueProperty[0].minValue
                  ? "Min:" +
                  expectation.valueProperty[0].minValue +
                  ", Max:" +
                  expectation.valueProperty[0].maxValue
                  : expectation.valueProperty[0].value}
              </span>
            )}
          </td>
        </tr>
        <tr>
          <th className="text-right">Last Test Result:</th>
          {reading && reading.value && (
            <td>Reading Value: {reading.value.toString()},</td>
          )}
          {reading && reading.values && (
            <td>Reading Value: {reading.values.toString()},</td>
          )}
        </tr>
      </>
    );
  } else {
    var vitalException = "";
  }
  const imgStyling = {
    width: "150px",
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 15
  };
  const noMargin = true;
  const { fname, lname, isActive, profileImage, isCareProvider } = violatedBy;
  const grey = isActive
    ? { ...imgStyling }
    : {
      ...imgStyling,
      "-webkit-filter": "grayscale(100%)",
      filter: "grayscale(100%)"
    };
  const answerStatus = (cell, row) => {
    const finalGrandRPM = row.isCurrectAnswer;
    return (
      <div className="text-center">
        {finalGrandRPM && (
          <button
            className="btn btn-info correct-click-cls "
            onClick={() => {
              //extra.openInterventionReport(row.trackTime[0]);
            }}>
            Correct
          </button>
        )}

        {!finalGrandRPM && (
          <button
            className="btn btn-info wrong-click-cls"
            onClick={() => {
              //extra.openInterventionReport(row.trackTime[0]);
            }}>
            Incorrect
          </button>
        )}
      </div>
    );
  };
  function formatDate(cell) {
    return moment(cell).format("MMM DD, YYYY hh:mm:ss A");
  }
  const imageDefault = isCareProvider ? doctorImage : userImage;
  return (
    <div>
      <span className={`align-items-center mb-4`}>
        <button
          className="text-white mr-auto btn btn-gradient-dark btn-icon-text"
          onClick={goBack}
        >
          <i className="mdi mdi-arrow-left btn-icon-prepend" />
          EXCEPTIONS
        </button>
        <Link
          to={`/dashboard/${violatedBy.fname
            .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
            .toLowerCase()}-${violatedBy.lname
              .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
              .toLowerCase()}/${violatedBy._id}`}
          className="text-white ml-3 btn btn-success btn-icon-text"
        >
          View Patient Charts
        </Link>
      </span>

      <Row>
        <Col xs={3}>
          {" "}
          <div
            className={isActive ? "" : " text-danger"}
          >
            <img
              style={{ borderRadius: '100%', ...grey }}
              src={false ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + profileImage : imageDefault}
              className={noMargin ? "" : "mr-2"}
              alt="image"
              width={145}
              height={145}
            />
          </div>
          <div style={{
            padding: 20
          }}>
            <span className="font-weight-bold">
              {lname}, {fname}
            </span>
            <div>Phone: {violatedBy.phone}</div>
            <div>Gender: {violatedBy.gender}</div>
            <div>
              Main Care Provider: {createdBy.fname + " " + createdBy.lname}
            </div>
            <div>Main Care Provider Phone: {createdBy.phone || "---"}</div>
          </div>
        </Col>
        <Col xs={9}>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={4}>
                  <Card className="card overflow-hidden card-design-details">
                    <CardBody>
                      <CardTitle className="font-weight-bold">
                        Exception Description:
                      </CardTitle>
                      <CardSubtitle>
                        {responseData.name} ({responseData.ruleType})
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={4}>
                  <Card className="card overflow-hidden card-design-details">
                    <CardBody>
                      <CardTitle className="font-weight-bold">
                        Occurred:
                      </CardTitle>
                      <CardSubtitle>
                        {" "}
                        {responseData?.createdAt
                          ? moment(responseData.createdAt).format(
                            "MMMM DD, YYYY hh:mm A"
                          )
                          : "---"}
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={4}>
                  <Card className="card overflow-hidden card-design-details">
                    <CardBody>
                      <CardTitle className="font-weight-bold">
                        Care Provider & Staff Exception Message:
                      </CardTitle>
                      <CardSubtitle>
                        {responseData?.message ? responseData.message : "---"}
                      </CardSubtitle>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card className="card overflow-hidden card-design-details">
                    <div
                      id="printQuestionAsnwer"
                      className="pb-12"
                      style={{ backgroundColor: "white" }}
                    >
                      <BootstrapTable
                        wrapperClasses="table-responsive"
                        keyField="_id"
                        noDataIndication="No Data Found"
                        data={answers}
                        columns={[
                          {
                            dataField: "questionName",
                            text: "Question",
                            sort: true
                          },
                          {
                            dataField: "answer",
                            text: "Answer",
                            sort: true
                          },
                          {
                            dataField: "createdAt",
                            text: "Date",
                            sort: true,
                            formatter: (cell, row) => {
                              return formatDate(row.createdAt);
                            }
                          },
                          {
                            dataField: "isCurrectAnswer",
                            text: "Answer status",
                            sort: true,
                            formatter: answerStatus
                          },
                        ]}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ careprovider,user }) => {
  const { usersOfferedAccess, usersOfferedAccessOrganization } = careprovider;
  const role = user.userDetails.role;
  return {
    usersOfferedAccess,
    usersOfferedAccessOrganization,
    role
  };
};

export default connect(mapStateToProps)(ViolationDetailQuestion);
