import React, { useState, useEffect } from "react";

const ViolationsSearchBar = ({ setSearchQuery, searchQuery, isCareProvider, isAdminPatient }) => {
  
  const [placeholder, setPlaceholder] = useState("");

  const handleChange = (e) => {
    const val = e.target.value.replace(/[^\w\s]/gi, "");
    setSearchQuery(val);
  };

  useEffect(() => {
    if(isCareProvider && !isAdminPatient){
      setPlaceholder("Search by title, reading sub type, patient, patient nickname")
    }else {
      setPlaceholder("Search by title, reading sub type")
    }
  }, [isCareProvider, isAdminPatient])

  return (
    <div>
      <div className="form-group">
        <input
          type="text"
          className="form-control input-date-filter remove-bottom-margin"
          id="exampleInputName1Test"
          style={{ fontSize: "15px" }}
          placeholder={placeholder}
          value={searchQuery}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default ViolationsSearchBar;
