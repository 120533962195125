import React from "react";
import Link from "components/ExtendLink";

import { Nav, NavItem, NavLink } from "reactstrap";
import population from "files/population.svg";
import onBoardingMenu from "../../../files/ic_menu_on_boarding.png";
import faxReport from "../../../files/ic_fax_report.svg";
import { matchPath, withRouter } from "react-router";

const Sidebar = (props) => {
  const sidebarColor = props.clientInfo
    ? props.clientInfo.sidebarColor || "#ffffff"
    : "";
  const iconColor = props.clientInfo
    ? "" || "#222222" //props.clientInfo.iconColor
    : "";
  const isOpenSidebar = props.isOpenSidebar ? "active" : "mt-4";
  

  return (
    <div
      className="sidebar"
      onClick={() => {
        if (props.windowSize < "1199") {
          props.openSidebar();
        }
      }}
    >
      <div className="main-menu">
        <div className="scroll">
          <div className="scrollbar-container ps">
            <ul className="list-unstyled nav flex-column">
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/quick-access",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/quick-access" className="">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-table-settings icon-lg"
                  />
                  <span className="" style={{ color: iconColor }}>
                    HOME
                  </span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/reports",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/reports" className="">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-printer icon-lg"
                  />
                  <span className="" style={{ color: iconColor }}>
                    REPORTS
                  </span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/:client?/ccm-records",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/ccm-records" className="">
                  <i
                    style={{ color: iconColor }}
                    className={`mdi mdi-monitor icon-lg ${
                      iconColor || "text-dark"
                    }`}
                  />
                  <span className="" style={{ color: iconColor }}>
                    PM SESSIONS
                  </span>
                </NavLink>
              </NavItem>
              <NavItem
              active={
                !!matchPath(props.location.pathname, {
                  path: "/virtual-call-logs",
                  exact: true,
                  strict: true,
                })
              }
              >
                <NavLink tag={Link} to="/virtual-call-logs" className="">
                  <i style={{ color: iconColor }} className="mdi mdi-phone-log icon-lg"/><span style={{ color: iconColor }}>Virtual call</span>
                </NavLink>
              </NavItem>
              {props.user.role == "admin" && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/support",
                      exact: true,
                      strict: false
                    })
                  }
                >
                  <NavLink tag={Link} to="/support">
                    <i
                      style={{ color: iconColor }}
                      className="mdi mdi-account-group icon-lg"
                    />
                    <span style={{ color: iconColor }}>SUPPORT USERS</span>
                  </NavLink>
                </NavItem>
              )}              
              {/*
                {props.user.role == "admin" && (
                <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/home-health-agents",
                      exact: true,
                      strict: false
                    })
                  }
                >
                  <NavLink tag={Link} to="/home-health-agents">
                    <i
                      style={{ color: iconColor }}
                      className="mdi mdi-account-multiple icon-lg"
                    />
                    <span style={{ color: iconColor }}>HOME HEALTH AGENTS</span>
                  </NavLink>
                </NavItem>
              )}
              */}
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/action-logs",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/action-logs" className="">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-gesture-double-tap icon-lg"
                  />
                  <span className="" style={{ color: iconColor }}>
                    ACTION LOGS
                  </span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/account-management",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/account-management">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-account-child-outline icon-lg"
                  />
                  <span style={{ color: iconColor }}>ACCOUNTS MANAGEMENT</span>
                </NavLink>
              </NavItem>
              {/* <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/plans",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/plans">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-playlist-check icon-lg"
                  />
                  <span style={{ color: iconColor }}>PLANS</span>
                </NavLink>
              </NavItem> */}
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/clients",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/clients">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-account-tie-outline icon-lg"
                  />
                  <span style={{ color: iconColor }}>CLIENTS</span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/careproviders",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/careproviders">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-doctor icon-lg"
                  />
                  <span style={{ color: iconColor }}>
                    CAREPROVIDERS / STAFF
                  </span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/users",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/users">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-account-multiple icon-lg"
                  />
                  <span style={{ color: iconColor }}>PATIENTS</span>
                </NavLink>
              </NavItem>
              <NavItem
                  active={
                    !!matchPath(props.location.pathname, {
                      path: "/:client?/shared-account",
                      exact: true,
                      strict: false,
                    })
                  }
                ><NavLink tag={Link} to="/shared-account" className="">
                    <i
                      style={{ color: iconColor }}
                      className={`mdi mdi-doctor icon-lg  ${
                        iconColor || "text-success"
                      }`}
                    />
                    <span className="" style={{ color: iconColor }}>
                      Shared/Family Account
                    </span>
                  </NavLink>
                </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/onboarding",
                    exact: true,
                    strict: true,
                  })
                }
              >
                <NavLink tag={Link} to="/onboarding" className="">
                  <span style={{ color: iconColor }}><img className="img-tabsfluid" src={onBoardingMenu} style={{ marginRight: 5 }} />RPM On-Boarding</span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/offboarding",
                    exact: true,
                    strict: true,
                  })
                }
              >
                <NavLink tag={Link} to="/offboarding" className="">
                  <span style={{ color: iconColor }}><img className="img-tabsfluid" src={onBoardingMenu} style={{ marginRight: 5 }} />RPM Off-Boarding</span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/onboardingccm",
                    exact: true,
                    strict: true,
                  })
                }
              >
                <NavLink tag={Link} to="/onboardingccm" className="">
                  <span style={{ color: iconColor }}><img className="img-tabsfluid" src={onBoardingMenu} style={{ marginRight: 5 }} />CCM On-Boarding</span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/fax-report",
                    exact: true,
                    strict: true,
                  })
                }
              >
                <NavLink tag={Link} to="/fax-report" className="">
                  <span style={{ color: iconColor }}><img className="img-tabsfluid" src={faxReport} style={{ marginRight: 5 }} />Fax Report</span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/population",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/population">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-finance icon-lg"
                  />
                  <span style={{ color: iconColor }}>ANALYZE REPORT</span>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  !!matchPath(props.location.pathname, {
                    path: "/settings",
                    exact: true,
                    strict: false
                  })
                }
              >
                <NavLink tag={Link} to="/settings">
                  <i
                    style={{ color: iconColor }}
                    className="mdi mdi-settings icon-lg"
                  />
                  <span style={{ color: iconColor }}>SETTINGS</span>
                </NavLink>
              </NavItem>
            </ul>
            <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
              <div
                className="ps__thumb-x"
                tabIndex={0}
                style={{ left: "0px", width: "0px" }}
              />
            </div>
            <div
              className="ps__rail-y"
              style={{ top: "0px", right: "0px", height: "568px" }}
            >
              <div
                className="ps__thumb-y"
                tabIndex={0}
                style={{ top: "0px", height: "0px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
