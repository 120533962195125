import React from "react";
import moment from "moment";
import { Badge } from 'reactstrap';
import { timeFormate } from "../../constants/general.constant";
const _ = require("lodash");

function VirtualVisitCallLogs(props) {
    const { virtualVisitCPTCodes } = props;
    const { CPTCodeA, CPTCodeB, CPTCodeC, CPTCodeD, careProviderCallTotalDuration, staffCallTotalDuration } = virtualVisitCPTCodes;

    function displayData(data, color) {
        return (
            <tr>
                <td style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '5px'}}>{data.length > 0 ? _.map(data, (value, index) => ( <button key={index} className={`btn btn-sm ${color ? color : 'btn-info'} session-code-button-radia text-white`} style={{ pointerEvents: 'none'}}> { value } </button> )) : '-'}</td>
                <td className="text-center">{data.length > 0 ? <Badge color="success" pill outline> MET </Badge> : <Badge color="danger" pill outline> NOT MET </Badge>}</td>
            </tr>
        )
    }
    return (
        <div>
        {((CPTCodeA && CPTCodeA.length > 0) || (CPTCodeB && CPTCodeB.length > 0) || (CPTCodeC && CPTCodeC.length > 0) || (CPTCodeD && CPTCodeD.length > 0)) && (<div>
           <hr style={{ borderTop: "3px solid #5c5959" }} />
           <div className="text-center text-dark">
               <h3 class="font-weight-bold">
                   Virtual Visit Call CPT Codes Billing Requirements
               </h3>
           </div>
           {(CPTCodeA && CPTCodeA.length > 0 || CPTCodeB && CPTCodeB.length > 0) && (
           <div  className="text-center text-dark">
           <h3 class="font-weight-bold">
                   Careprovider Total Duration : {timeFormate(careProviderCallTotalDuration)}
           </h3>
           </div>
           )}
            {CPTCodeA && CPTCodeA.length > 0 && (
                <div>
                    <table class="table table-bordered page-break-ka-after mb-4">
                        <thead>
                            {CPTCodeA && (
                                <tr>
                                    <th className="text-center">CPT Code A</th>
                                    <th className="text-center" style={{width : '15%'}}>Status</th>
                                </tr>
                            )}
                        </thead>
                        <tbody className="text-center">
                            {CPTCodeA ? (
                                 displayData(CPTCodeA, 'btn-info')
                            ) : (
                                <tr>
                                    <th
                                        colSpan="2"
                                        className="text-black text-danger text-center mx-auto"
                                    >
                                        No Record Found
                                    </th>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
            {CPTCodeB && CPTCodeB.length > 0 && (
                <div>
                    <table class="table table-bordered page-break-ka-after mb-4">
                        <thead>
                            {CPTCodeB && (
                                <tr>
                                    <th className="text-center">CPT CODE B</th>
                                    <th className="text-center" style={{width : '15%'}}>Status</th>
                                </tr>
                            )}
                        </thead>
                        <tbody className="text-center">
                            {CPTCodeB ? (
                                displayData(CPTCodeB, 'btn-warning')
                            ) : (
                                <tr>
                                    <th
                                        colSpan="2"
                                        className="text-black text-danger text-center mx-auto"
                                    >
                                        No Record Found
                                    </th>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
            {(CPTCodeC && CPTCodeC.length > 0 || CPTCodeD && CPTCodeD.length > 0) && (
            <div className="text-center text-dark">
            <h3 class="font-weight-bold">
             Staff Total Duration : {timeFormate(staffCallTotalDuration)}
            </h3>
            </div>
            )}
            {CPTCodeC && CPTCodeC.length > 0 && (
                <div>
                    <table class="table table-bordered page-break-ka-after mb-4">
                        <thead>
                            {CPTCodeC && (
                                <tr>
                                    <th className="text-center">CPT CODE C</th>
                                    <th className="text-center" style={{width : '15%'}}>Status</th>
                                </tr>
                            )}
                        </thead>
                        <tbody className="text-center">
                            {CPTCodeC ? (
                               displayData(CPTCodeC, 'btn-primary')
                            ) : (
                                <tr>
                                    <th
                                        colSpan="2"
                                        className="text-black text-danger text-center mx-auto"
                                    >
                                        No Record Found
                                    </th>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
            {CPTCodeD && CPTCodeD.length > 0 && (
                <div>
                    <table class="table table-bordered page-break-ka-after mb-4">
                        <thead>
                            {CPTCodeD && (
                                <tr>
                                    <th className="text-center">CPT CODE D</th>
                                    <th className="text-center" style={{width : '15%'}}>Status</th>
                                </tr>
                            )}
                        </thead>
                        <tbody className="text-center">
                            {CPTCodeD ? (
                               displayData(CPTCodeD, 'btn-success')
                            ) : (
                                <tr>
                                    <th
                                        colSpan="2"
                                        className="text-black text-danger text-center mx-auto"
                                    >
                                        No Record Found
                                    </th>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
         </div>)}
        </div>
    )
}
export default VirtualVisitCallLogs;