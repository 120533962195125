import React, { Component } from 'react';
import _, { filter } from "lodash";
import { connect } from 'react-redux';
import OffBoardingDataTable from './OffBoardingDataTable';
import moment from "moment";
import {
  Col,
  Label,
  Row,
  FormGroup,
  Input
} from "reactstrap";
import Select from "react-select";
import RequiredSelect from "../Rules/RequiredSelect";

class OffBoardingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      search: "",
      enrolledPrograms: "",
      displayActiveList: true,
      displayDemoList: null,
      displayCellularConnectivityList: false,
      fileLoading: false,
      startDate: "",
      endDate: "",
      endDateError: null,
      link: undefined,
      shippedStatus: undefined,
      device: undefined,
      deviceModel: undefined,
      sortField : 'lastNoteCreatedDate',
      sortOrder : 'desc'
    };
    this.timeout =  null;
    this.onNavigatePage = this.onNavigatePage.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.filterShippedStatus = this.filterShippedStatus.bind(this);
    this.fetchDataFromServer = this.fetchDataFromServer.bind(this);
    this.handleInputDateChange = this.handleInputDateChange.bind(this);
    this.handleDeviceChange = this.handleDeviceChange.bind(this);
  }

  onNavigatePage(page, limit) {
    //console.log("page : " + page)
  }
  handleDeviceChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;    
    this.setState({
      device: value && value != "-1" ? value : undefined,
    }, () => {
      this.fetchData();
    });  
  }
  handleTableChange(type, { page, sizePerPage, filters, sortField, sortOrder }) {
    let data = {
      page: page, limit: sizePerPage
    }
    if(type === 'sort'){
      if(this.state.sortOrder == "desc"){
        data.sortOrder = "asc"
      }else{
        data.sortOrder = "desc"
      }
    }
    if(sortField){
      data.sortField = sortField
    }
    this.setState(data, () => {
      this.fetchData();
    });
  }
  filterShippedStatus(event) {
    if (event.target.value) {
      this.setState({
        page: 1,
        sortField: "lastNoteCreatedDate",
        sortOrder: "desc",
        shippedStatus: event.target.value
      }, () => {
        this.fetchData();
      });
    } else {
      this.setState({
        shippedStatus: undefined
      }, () => {
        this.fetchData();
      });
    }
  }
  componentDidMount() {
    this.props.fetchDataFromServer(this.fetchDataFromServer);
  }
  fetchDataFromServer() {
    this.setState({
      page: 1,
      limit: 10,
      search: "",
      shippedStatus: "",
      endDateError: null,
      device: "",
      startDate:"",
      endDate:"",
      sortField: "lastNoteCreatedDate",
      sortOrder: "desc",
      deviceModel: ""
    }, () => {
      this.fetchData();
    });
  }
  fetchData() {
    this.props.dispatch({
      type: "DO_GET_ALL_ON_BOARDING_DATA",
      payload: {
        page: this.state.page,
        limit: this.state.limit,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        sortField: this.state.sortField ? this.state.sortField : "",
        sortOrder: this.state.sortOrder ? this.state.sortOrder : "desc",
        search: this.state.search ? this.state.search : "",
        device: this.state.device ? this.state.device : "",
        catagoryID: this.props.catagoryID,
        shippedStatus: this.state.shippedStatus ? this.state.shippedStatus : undefined,
        onBoarding : false
      }
    });
  }
  MyExportCSV = (props) => {
    const handleClick = () => {
      this.props.dispatch({
        type: "DO_REMOVE_PATIENT_LINK_PATH",
        payload: undefined
      });
      var tz = moment.tz.guess();
      this.props.dispatch({
        type: "DO_EXPORT_ONBOARDING_LIST",
        payload: {
          tz:tz,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          device: this.state.device ? this.state.device : "",
          sortField: this.state.sortField ? this.state.sortField : "",
          sortOrder: this.state.sortOrder ? this.state.sortOrder : "",
          catagoryID: this.props.catagoryID,
          shippedStatus: this.state.shippedStatus ? this.state.shippedStatus : undefined,
          onBoarding: false
        }
      });
    };
    return (
      <Row>
        <Col>
          <button 
          style={{ marginTop: 5,width:164, height:43 }} 
          className="btn btn-info ml-2 download-click-cls"
          onClick={handleClick}
          disabled={this.props.ongoingOnBoardingExportRequest ? true : false}
          >
          {(this.props.ongoingOnBoardingExportRequest) ? (
            <i className="mdi mdi-loading mdi-spin mr-2" style={{ fontSize: 20 }}></i> // Loader icon
          ) : (
              <i className="mdi mdi-download" style={{ fontSize: 20 }}></i>
          )}
          Export to CSV
          </button>
        </Col>
      </Row>
    );
  };
  MySearch = () => {
    let input;
    const handleChange = (e) => {
      e.preventDefault();
      const val = e.target.value.replace(/[^\w\s]/gi, "")
      this.setState({
        page: 1,
          search: val,
          sortField: "lastNoteCreatedDate",
          sortOrder: "desc",
      });
    };
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        this.fetchData();        
      }, 200)
      // if this effect run again, because `value` changed, we remove the previous timeout
      return () => clearTimeout(timeout)
    }, [this.state.search])
    return (
      <div>
        <div class="form-group">
          <input
            type="text"
            class="form-control input-date-filter remove-bottom-margin"
            id="exampleInputName1Test"
            style={{ fontSize: "20px" }}
            placeholder="Search Last Name, First Name, Organization, Enrolled Programs"
            ref={(n) => (input = n)}
            value={this.state.search}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };
  /**
   * Questionnaire date inputs
   * @param {*} event 
   */
  handleInputDateChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      { [name]: value }, () => {
        this.isEndDateValid(this.state.endDate)
        this.fetchData()
      }
    );
  }
  isEndDateValid(value) {
    if (value === undefined || value === null || value.trim().length == 0) {
      return false;
    } else {
      var date = moment(new Date(this.state.startDate), 'MM/DD/YYYY');
      date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      var dateEntered = new Date(value);
      var today = moment(date, 'MM/DD/YYYY');
      var enteredDate = moment(dateEntered, 'MM/DD/YYYY');
      var dDiff = today.isSameOrAfter(enteredDate);
      if (dDiff) {
        this.setState({
          endDateError: "Please select end date is greater than equal to start date."
        });
        return false;
      } else if (today.isSame(enteredDate)) {
        this.setState({
          endDateError: null
        });
        return true;
      } else {
        this.setState({
          endDateError: null
        });
        return true;
      }
    }
  };
  render() {
    if (this.props.patientFilePath) {
      if (this.state.link === undefined) {
        this.setState({
          link: this.props.patientFilePath,
        }, () => {
          this.props.dispatch({
            type: "DO_REMOVE_PATIENT_LINK_PATH",
            payload: undefined
          });
          window.open(this.state.link, "_self");
        }
        );
      }

    } else {
      this.state.link = undefined;
    }
    return (
      <Row className="col-sm-12 main-cls-modal remove-table-padding">
        <Col sm="12" className="mt-12">
          <div>
            <Row className={`col-md-12 mx-auto float-right`}>
              <Col md={2}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="exampleEmail">Filter by Start Date</Label>
                  <Input
                    className="input-date-filter remove-bottom-margin"
                    type="date"
                    name="startDate"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={this.handleInputDateChange}
                    value={this.state.startDate}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">Filter by End Date</Label>
                  <Input
                    className="input-date-filter remove-bottom-margin"
                    type="date"
                    name="endDate"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={this.handleInputDateChange}
                    value={this.state.endDate}
                  />
                  <Label
                    style={{
                      color: "red",
                      textAlign: "left",
                      padding: 5
                    }}>{this.state.endDateError}</Label>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">Filter by Shipment Device Status:&nbsp;&nbsp;</Label>
                  <select className="filter-Questionnaire" onChange={this.filterShippedStatus} value={this.state.shippedStatus}>
                    <option value="">Select Shipment Device Status</option>
                    <option value="All">All Device</option>
                    <option value="Shipped">Shipped Device</option>
                    <option value="Returned">Returned Device</option>
                  </select>
                </FormGroup>                
              </Col>
              <Col md={3}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">Filter by Shipment Device:&nbsp;&nbsp;</Label>                  
                  {this.props.allBoardingDevice && this.props.allBoardingDevice.length > 0 && (
                    <>
                      <select className="filter-Questionnaire" onChange={this.handleDeviceChange} value={this.state.device}>
                        <option value="-1"> Select Device </option>
                        {this.props.allBoardingDevice && this.props.allBoardingDevice.map((model) =>
                          <option value={model["_id"]}>{model["name"]}</option>)}
                      </select>
                    </>
                  )}
                </FormGroup>                
              </Col>              
              <Col md={2}>
                <FormGroup className="remove-bottom-margin form-group-patient">
                  <Label for="examplePassword">&nbsp;&nbsp;</Label>
                  {(this.state.startDate || this.state.endDate || this.state.search || this.state.shippedStatus || this.state.device) && (
                    <button
                      style={{ float: 'left', marginTop: 20, width:150, height:37 }}
                      onClick={() => {
                        this.fetchDataFromServer()
                      }}
                      className="btn btn-info ml-2 close-click-cls"
                    >
                      <i class="mdi mdi-close" style={{
                        fontSize: 20
                      }}></i>
                      Clear Filters
                    </button>
                  )}
                </FormGroup>
              </Col>                            
            </Row>
            <Row className={`text-center col-md-12 mx-auto float-right`}>
              <Col md={10} style={{ marginTop: 15 }}>
                <this.MySearch />
              </Col>
              <Col md={2} style={{ marginTop: 10 }}>
                <this.MyExportCSV>
                  Export CSV
                </this.MyExportCSV>
              </Col>
            </Row>
          </div>
          {this.props.ongoingOnBoardingRequest && (
            <OffBoardingDataTable
              data={[]}
              page={this.state.page}
              loading={this.props.ongoingOnBoardingRequest}
              sizePerPage={this.state.limit}
              profileOwner={this.props.profileOwner}
              readingSubTypes={this.props.readingSubTypes}
              userId={this.props.userId}
              totalSize={0}
              onNavigatePage={this.onNavigatePage}
              onTableChange={this.handleTableChange}
              isShowPaggination={true}              
            />
          )}
          {!this.props.ongoingOnBoardingRequest && this.props.allOnBoradingAccess && (
            <OffBoardingDataTable
              data={this.props.allOnBoradingAccess}
              allCategorys={this.props.allCategorys}
              page={this.state.page}
              loading={this.props.ongoingOnBoardingRequest}
              sizePerPage={this.state.limit}
              profileOwner={this.props.profileOwner}
              readingSubTypes={this.props.readingSubTypes}
              userId={this.props.userId}
              totalSize={this.props.totalRecode}
              onNavigatePage={this.onNavigatePage}
              onTableChange={this.handleTableChange}
              isShowPaggination={true}
            />
          )}
        </Col>
      </Row>
    );
  }
}

// function mapStateToProps(state, ownProps) {
//   return {
//     products: state.products
//   };
// }

const mapStateToProps = ({ careprovider, user, auth }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    allOnBoradingAccess,
    allCategorys,
    allBoardingDevice,
    totalRecode,
    ongoingOnBoardingRequest,
    patientFilePath,
    ongoingOnBoardingExportRequest
  } = careprovider;
  const { readingSubTypes } = auth;
  const userId = user.userDetails._id;
  const profileOwner = user.profileOwner;
  const isAdmin = user.userDetails && user.userDetails.role === "support" || user.userDetails.role === "admin" ? true : false ;
  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    totalRecode,
    allOnBoradingAccess,
    allCategorys,
    allBoardingDevice,
    readingSubTypes,
    userId,
    ongoingOnBoardingRequest,
    profileOwner,
    patientFilePath,
    isAdmin,
    ongoingOnBoardingExportRequest
  };
};

export default connect(mapStateToProps)(OffBoardingList);
