// Decalred All Violation Helper Functions
import React from "react";
import AdherenceIcon from "../../../files/adherence.svg";
import ReminderIcon from "../../../files/reminder.jpg";
import TimeIcon from "../../../files/timeadherence.jpg";
import TrendingIcon from "../../../files/trending.svg";
import userImage from "../../../files/user.png";
import VitalsIcon from "../../../files/vitals.jpg";
import WeightIcon from "../../../files/weight.svg";
import QuestionnaireIcon from ".././../../files/questionnaire.svg";
import Link from "components/ExtendLink";
import moment from "moment";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export function withIcon(cell) {
  let icon;
  switch (cell) {
    case "Vitals":
      icon = VitalsIcon;
      break;
    case "Time Adherence":
      icon = TimeIcon;
      break;
    case "Adherence":
      icon = AdherenceIcon;
      break;
    case "Reminder":
      icon = ReminderIcon;
      break;
    case "Trending Violation":
      icon = TrendingIcon;
      break;
    case "Weight":
      icon = WeightIcon;
      break;
    case "Questionnaire":
      icon = QuestionnaireIcon;
      break;
    default:
      break;
  }
  return (
    <span>
      <img src={icon} className="mr-2" alt="image" />
      {cell}
    </span>
  );
}

export const MyExportCSV = ({ filterData, CSVTitle, isAdminPatient, isClientPatient, patientId, isCareProvider, isCareProviderPatient}) => {

  const dispatch = useDispatch();
  const { ongoingUserViolationCSVExportRequest, usersViolationsCSVUrl } = useSelector((state) => state.careprovider);

  useEffect(() => {
      if(usersViolationsCSVUrl){
        window.open(usersViolationsCSVUrl, "_self");
        // Reset the CSV URL before fetching new data
        dispatch({
          type: 'EXPORT_USER_VIOLATIONS_CSV_EXPORT',
          payload: { data: null }
        });
      }
  }, [usersViolationsCSVUrl])

  const handleClick = () => {

    let payload = {
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      sortField: filterData.sortField ? filterData.sortField : "",
      sortOrder: filterData.sortOrder ? filterData.sortOrder : "",
      ruleTypes: filterData?.ruleTypes ? filterData.ruleTypes : "",
      exeptionType: CSVTitle,
      isEventCondition: filterData.isEventCondition
    }

    if(!isCareProvider && !isAdminPatient && !isClientPatient && !isCareProviderPatient){
      payload = { ...payload, isPatient: true }
    }

    if((isAdminPatient || isClientPatient || isCareProviderPatient) && patientId){
      payload = { ...payload, patientId: patientId }
    }

    // Dispatch the action to fetch the new CSV URL
    dispatch({
      type: "DO_GET_USER_VIOLATIONS_CSV_EXPORT",
      payload: payload
    });

  };

  return (
    <Row>
    <Col>
      <button 
      style={{ width:164, height:43 }} 
      className="btn btn-info ml-2 download-click-cls"
      disabled={ongoingUserViolationCSVExportRequest}
      onClick={handleClick}>
      {(ongoingUserViolationCSVExportRequest) ? (
        <i className="mdi mdi-loading mdi-spin mr-2" style={{ fontSize: 20 }}></i> // Loader icon
      ) : (
        <>
          <i className="mdi mdi-download" style={{ fontSize: 20 }}></i>
        </>
      )}
      Export to CSV
      </button>
    </Col>
  </Row>
  );

};

export function fullname(cell) {
  if (cell) {
    const profileImage = cell.profileImage
      ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + cell.profileImage
      : userImage;
    return (
      <div>
        <img src={profileImage} className="mr-2" alt="image" />
        {cell.fname} {cell.lname}{" "}
        {cell?.mainCareProvider
          ? `(${cell?.mainCareProvider?.fname || ""} 
          ${cell?.mainCareProvider?.lname || ""})`
          : ""}
      </div>
    );
  } else {
    return "";
  }
}

export function nickname(row) {
  if (row) {
    return <div>{row ? row : "-"}</div>;
  } else {
    return "-";
  }
}

export function formatDate(cell) {
  return moment(cell).format("MMMM DD, YYYY hh:mm A");
}

export function priceFormatter(
  column,
  colIndex,
  { sortElement, filterElement }
) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}

export const vitalReading = (cell, row) => {
  if (row) {
    if (
      row ||
      row.ruleType == "Questionnaire" ||
      row.ruleType == "Trending Violation"
    ) {
      const { violatedBy, createdBy } = row;
      if (createdBy && row.ruleType != "Questionnaire") {
        return (
          <Link
            to={`/violation/details?identifier=${createdBy._id ? createdBy._id  : createdBy }tldr${violatedBy._id}&vuid=${row._id}`}
            className="text-white ml-auto btn btn-sm btn-success btn-rounded btn-fw"
          >
            More Details
          </Link>
        );
      }
      if (row.ruleType == "Questionnaire") {
        return (
          <Link
            to={`/violation/questionnairedetails?identifier=${createdBy._id}tldr${violatedBy._id}&vuid=${row._id}`}
            className="text-white ml-auto btn btn-sm btn-success btn-rounded btn-fw"
          >
            More Details
          </Link>
        );
      }
    }
  }
  return "";
};

export const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    {" "}
    Showing {from} to {to} of {size} Results
  </span>
);
