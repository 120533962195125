import React, { useEffect, useRef, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Row,
    Col,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
} from "reactstrap";
import LottieLoader from '../../components/LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import BootstrapTable from "react-bootstrap-table-next";
import UserName from "../Reusable/UserName";
import moment from "moment";
import Link from "components/ExtendLink";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useReactToPrint } from "react-to-print";
import { timeFormate } from "../../constants/general.constant";
const _ = require("lodash");

const PrintableContent = React.forwardRef(({ row }, ref) => {
    return (
        <div className='padding-card-body' ref={ref}>
            <BootstrapTable
                keyField='_id'
                data={row.videoCallLogs}
                columns={[
                    {
                        dataField: "createdBy",
                        text: "Care provider/Staff",
                        sort: false,
                        formatter: (cell, row) => <UserName user={cell} />,
                    },
                    {
                        dataField: "participants",
                        text: "Participants",
                        sort: false,
                        formatter: (cell, row) => {
                            if (cell) {
                                return cell.map((item, index) => (
                                    <div key={index}>
                                        {`${item.participant.lname} ${item.participant.fname}`}
                                        {item.joinedAt && (
                                            <div>
                                                <br />
                                                <b>Joined At: </b>{moment(item.joinedAt).format("MMMM DD, YYYY hh:mm A")}
                                            </div>
                                        )}
                                        {item.leftAt && (
                                            <div>
                                                <br />
                                                <b>Left At: </b>{moment(item.leftAt).format("MMMM DD, YYYY hh:mm A")}
                                            </div>
                                        )}
                                        {index !== cell.length - 1 && <hr />}
                                    </div>
                                ));
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        dataField: "duration",
                        text: "Duration(hh:mm)",
                        sort: false,
                        formatter: (cell) => (cell !== 0 ? timeFormate(cell) : "00:00:00"),
                    },
                    {
                        dataField: "status",
                        text: "Status",
                        sort: false,
                        formatter: (cell) => {return (cell == 'Accept' ? <span class="badge bg-success" style={{'fontSize' : '90%', 'color' : 'white'}}>Accepted</span> : cell ? <span class="badge bg-danger" style={{'fontSize' : '90%', 'color' : 'white'}}>{cell}</span> : "-")},
                    },
                    {
                        dataField: "callType",
                        text: "Call Type",
                        sort: false,
                        formatter: (cell) => (cell ? cell : "-"),
                    },
                    {
                        dataField: "createdAt",
                        text: "Date",
                        sort: false,
                        formatter: (cell) => (cell ? moment(cell).format("MMMM DD, YYYY hh:mm A") : "-"),
                    }
                ]}
            />
        </div>
    );
});

function VirtualCallLogsList(props) {
    const contentRef = useRef();
    // Correct use of useReactToPrint hook
    const reactToPrintFn = useReactToPrint({
        content: () => contentRef.current, // Referencing content to print
    });
    useEffect(() => {
        if (props.virtualVisitCallLogsExport && !props.virtualVisitCallLogsExportRequest) {
            window.open(props.virtualVisitCallLogsExport, "_self");
            props.props.dispatch({
                type: "DO_REMOVE_VIRTUAL_CALL_LOGS_EXPORT_PATH",
                payload: undefined
            });
        }
    }, [props])
    // patient or careprovider first and last name
    function fullname(cell, row) {
        return <UserName user={row}></UserName>;
    }

    const MessageFormatter = ({ cell }) => {
        const [isExpanded, setIsExpanded] = useState(false);

        const toggleReadMore = () => {
            setIsExpanded(!isExpanded ? true : false);
        };
        if (cell && !isExpanded && cell.length <= 100) {
            return <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>{cell}</div>;
        } else if (cell && cell.length >= 100) {
            return (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}>
                    <div>
                        {cell && !isExpanded ? cell.substring(0, 100) : cell ? cell : "-"}
                    </div>
                    <button
                        onClick={toggleReadMore}
                        style={{
                            marginTop: '5px',
                            color: '#007bff',
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            padding: 0,
                        }}
                    >
                        {isExpanded ? '...Read Less' : '...Read More'}
                    </button>
                </div>
            );
        } else {
            return '-'
        }
    };
    // Opern patient details page
    const formatAction = (cell, row) => {
        return (
            <Link
                to={`/dashboard/${row.fname
                    .replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '')
                    .toLowerCase()}-${row.lname.replace(/\s+/g, "-").replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()}/${row._id
                    }`}
                className="btn pull-center more-btn"
                style={{ marginTop: 10 }}
            >
                <i class="mdi mdi-account-card-details"></i> More Details
            </Link>
        );
    };
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            {"   "}Showing {from} to {to} of {size} Results
        </span>
    );
    const options = {
        page: props.virtualVisitCallLogsData ? parseInt(props.virtualVisitCallLogsData.page) : 0,
        sizePerPage: props.virtualVisitCallLogsData ? parseInt(props.virtualVisitCallLogsData.limit) : 0,
        totalSize: props.virtualVisitCallLogsData ? parseInt(props.virtualVisitCallLogsData.total) : 0,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: "25",
                value: 25
            },
            {
                text: "50",
                value: 50
            },
            {
                text: "100",
                value: 100
            },
            {
                text: "200",
                value: 200
            }
        ] // A numeric array is also available. the purpose of above example is custom the text
    };
    const NoDataIndication = () => (
        <div>
            {props.loading && (
                <LottieLoader
                    loadLoop={true}
                    autoplayLoop={true}
                    source={lottieLoaderFile}
                    Lheight={150}
                    Lwidth={150}
                    subTitle={"Please Wait ..."}
                />
            )}
            {!props.loading && (
                <LottieLoader
                    loadLoop={false}
                    autoplayLoop={true}
                    source={lottieEmptyFile}
                    Lheight={300}
                    Lwidth={300}
                    title={"Sorry! No Virtual log record found."}
                />
            )}
        </div>
    );
    const cptCodeDisplay = (cell) => {
        let CPTCodeA;
        let CPTCodeB;
        let CPTCodeC;
        let CPTCodeD;
        // CPT Code A list display
        if (cell && cell.CPTCodeA && cell.CPTCodeA.length > 0) {
            CPTCodeA = []
            _.map(cell.CPTCodeA, (value, index) => {
                CPTCodeA.push(
                    <div key={`key-${index}-CPTCodeA`}>
                        <button
                            className="btn btn-sm btn-info session-code-button-radial"
                            id={`btn-${index}-CPTCodeA`} // Ensure button has a unique ID
                        >
                            {value}
                        </button>
                        <UncontrolledPopover
                            trigger="legacy"
                            placement="top"
                            target={`btn-${index}-CPTCodeA`} // Use the ID for the popover target
                            delay={{ show: 100, hide: 100 }} // Add a slight delay to ensure the element is in the DOM
                        >
                            <PopoverHeader>Description</PopoverHeader>
                            <PopoverBody>
                                There has been an attestation for a virtual call between 5 and 10 minutes with the care provider.
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                );
            });
        }

        // CPT Code B list display
        if (cell && cell.CPTCodeB && cell.CPTCodeB.length > 0) {
            CPTCodeB = []
            _.map(cell.CPTCodeB, (value, index) => {
                CPTCodeB.push(
                    <div key={`key-${index}-CPTCodeB`}>
                        <button
                            className="btn btn-sm btn-warning session-code-button-radial text-white"
                            id={`btn-${index}-CPTCodeB`} // Ensure button has a unique ID
                        >
                            {value}
                        </button>
                        <UncontrolledPopover
                            trigger="legacy"
                            placement="top"
                            target={`btn-${index}-CPTCodeB`} // Use the ID for the popover target
                            delay={{ show: 100, hide: 100 }} // Add a slight delay to ensure the element is in the DOM
                        >
                            <PopoverHeader>Description</PopoverHeader>
                            <PopoverBody>
                                There has been an attestation for a virtual call between 10 and 20 minutes with the care provider.
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                );
            });
        }

        // CPT Code C list display
        if (cell && cell.CPTCodeC && cell.CPTCodeC.length > 0) {
            CPTCodeC = []
            _.map(cell.CPTCodeC, (value, index) => {
                CPTCodeC.push(
                    <div key={`key-${index}-CPTCodeC`}>
                        <button
                            className="btn btn-sm btn-primary session-code-button-radial text-white"
                            id={`btn-${index}-CPTCodeC`} // Ensure button has a unique ID
                        >
                            {value}
                        </button>
                        <UncontrolledPopover
                            trigger="legacy"
                            placement="top"
                            target={`btn-${index}-CPTCodeC`} // Use the ID for the popover target
                            delay={{ show: 100, hide: 100 }} // Add a slight delay to ensure the element is in the DOM
                        >
                            <PopoverHeader>Description</PopoverHeader>
                            <PopoverBody>
                                There has been an attestation for a virtual call between 5 and 10 minutes with the staff.
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                );
            });
        }

        // CPT Code D list display
        if (cell && cell.CPTCodeD && cell.CPTCodeD.length > 0) {
            CPTCodeD = []
            _.map(cell.CPTCodeD, (value, index) => {
                CPTCodeD.push(
                    <div key={`key-${index}-CPTCodeD`}>
                        <button
                            className="btn btn-sm btn-success session-code-button-radial text-white"
                            id={`btn-${index}-CPTCodeD`} // Ensure button has a unique ID
                        >
                        {value}
                        </button>
                        <UncontrolledPopover
                            trigger="legacy"
                            placement="top"
                            target={`btn-${index}-CPTCodeD`} // Use the ID for the popover target
                            delay={{ show: 100, hide: 100 }} // Add a slight delay to ensure the element is in the DOM
                        >
                            <PopoverHeader>Description</PopoverHeader>
                            <PopoverBody>
                                There has been an attestation for a virtual call between 10 and 20 minutes with the staff.
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                );
            });
        }

        if (CPTCodeA || CPTCodeB || CPTCodeC || CPTCodeD) {
            return (
                <div className="mx-auto text-center">
                    {CPTCodeA} {CPTCodeB}
                    {CPTCodeC} {CPTCodeD}
                </div>
            );
        } else {
            return ('-');
        }
    }
    let columns = [
        {
            dataField: "fullNamel",
            text: "Last, First Name",
            // formatter: fullname,
            sort: true,
            formatter: fullname,
        },
        {
            dataField: "nickname",
            text: "Nickname",
            // formatter: fullname,
            sort: true,
            formatter: (cell, row) => {
                return (row.nickname ? row.nickname : "-");
            },
        },
        {
            dataField: "bday",
            text: "DOB",
            // formatter: fullname,
            sort: true,
            formatter: (cell, row) => {
                return moment(cell).format("MMMM DD, YYYY");
            },
        },

        {
            dataField: "createdAt",
            text: "Enrolled Date",
            sort: true,
            formatter: (cell, row) => {
                return moment(cell).format("MMMM DD, YYYY");
            },
        },
        {
            dataField: "mainCareProvider",
            text: "Main Care Provider",
            sort: false,
            formatter: (cell, row) => {
                return (cell ? cell.lname + " " + cell.fname : "-");
            },
        },
        {
            dataField: "organization",
            text: "Organization",
            sort: true,
            formatter: (cell, row) => {
                return (cell ? cell : "-");
            },
        },
        {
            dataField: "cptCodeData",
            text: "Careprovider Call Duration",
            sort: false,
            formatter: (cell, row) => {
            if(cell && cell.careProviderCallTotalDuration){
                return timeFormate(cell.careProviderCallTotalDuration);
            }else{
                return "00:00:00"
            }
            },
        },
        {
            dataField: "cptCodeData",
            text: "Staff Call Duration",
            sort: false,
            formatter: (cell, row) => {
            if(cell && cell.staffCallTotalDuration){
                return timeFormate(cell.staffCallTotalDuration);
            }else{
                return "00:00:00"
            }
            },
        },
        {
            dataField: "cptCodeData",
            text: "Billable CPT Code(s)",
            sort: false,
            formatter: (cell, row) => {
                return cptCodeDisplay(cell)
            },
        },
        {
            dataField: "moreDetails",
            text: "More Details",
            sort: false,
            isDummy: true,
            formatter: formatAction,
            style: { width: '10%' }
        },
    ]
    const rowComponent = (row) => {
        return (
            <div>
                <Row style={{ margin: "15px 0px" }}>
                    <Col sm="12" className="mt-2 remove-summary-paddding">
                        <Card style={{ border: "0px 10px 10px 0px" }}>
                            {!row || (row && row.videoCallLogs && row.videoCallLogs.length == 0) ?
                                <div className="text-center">
                                    <NoDataIndication />
                                </div>
                                : <div>
                                    <CardHeader className="text-center summary-title summary-header">
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                            <div style={{ flex: 1, textAlign: 'center' }}>Virtual call logs</div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <button
                                                    style={{ marginTop: 5, width: 200, height: 36 }}
                                                    className="btn btn-info ml-2 download-click-cls"
                                                    onClick={() => props.exportCSV(row)}
                                                    disabled={props.virtualVisitCallLogsExportRequest}
                                                >
                                                    {props.virtualVisitCallLogsExportRequest ? <i className="mdi mdi-loading mdi-spin mr-2" style={{ fontSize: 20 }}></i> : <i className="mdi mdi-download" style={{ fontSize: 20 }}></i>}
                                                    Export to CSV Logs
                                                </button>
                                                <button
                                                    className="btn btn-info ml-2 print-click-cls"
                                                    style={{ margin: 5, padding: "10px 26px" }}
                                                    onClick={reactToPrintFn}
                                                >
                                                    <i className="mdi mdi-cloud-print"></i>
                                                    Print Logs
                                                </button>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <div className="hide">
                                        <PrintableContent ref={contentRef} row={row} />
                                    </div>
                                    <CardBody className='padding-card-body'>
                                        <BootstrapTable
                                            keyField='_id'
                                            data={row.videoCallLogs}
                                            columns={[
                                                {
                                                    dataField: "createdBy",
                                                    text: "Care provider/Staff",
                                                    sort: false,
                                                    formatter: (cell, row) => { return <UserName user={cell} /> }
                                                },
                                                {
                                                    dataField: "participants",
                                                    text: "Participants",
                                                    sort: false,
                                                    formatter: (cell, row) => {
                                                        if (cell) {
                                                            return cell.map((item, index) => (
                                                                <div key={index}>
                                                                    {`${item.participant.lname} ${item.participant.fname}`}
                                                                    {item.joinedAt && (
                                                                        <div>
                                                                            <br />
                                                                            <b>Joined At: </b>{moment(item.joinedAt).format("MMMM DD, YYYY hh:mm:ss A")}
                                                                        </div>
                                                                    )}
                                                                    {item.leftAt && (
                                                                        <div>
                                                                            <br />
                                                                            <b>Left At: </b>{moment(item.leftAt).format("MMMM DD, YYYY hh:mm:ss A")}
                                                                        </div>
                                                                    )}
                                                                    {index !== cell.length - 1 && <hr />}
                                                                </div>
                                                            ));
                                                        } else {
                                                            return "-";
                                                        }
                                                    }
                                                },
                                                {
                                                    dataField: "duration",
                                                    text: "Duration(hh:mm:ss)",
                                                    sort: false,
                                                    formatter: (cell, row) => {
                                                        return (cell ? timeFormate(cell) : "00:00:00");
                                                    },
                                                },
                                                {
                                                    dataField: "status",
                                                    text: "Status",
                                                    sort: false,
                                                    formatter: (cell, row) => {
                                                        return (cell == 'Accept' ? <span class="badge bg-success" style={{'fontSize' : '90%', 'color' : 'white'}}>Accepted</span> : cell ? <span class="badge bg-danger" style={{'fontSize' : '90%', 'color' : 'white'}}>{cell}</span> : "-");
                                                    },
                                                },
                                                {
                                                    dataField: "message",
                                                    text: "Message",
                                                    sort: false,
                                                    headerStyle: (colum, colIndex) => {
                                                        return { width: '20%', textAlign: 'left' };
                                                    },
                                                    formatter: (cell, row) => <MessageFormatter cell={cell} />,
                                                },                                          
                                                {
                                                    dataField: "callType",
                                                    text: "Call Type",
                                                    sort: false,
                                                    formatter: (cell, row) => {
                                                        return (cell ? cell : "-");
                                                    },
                                                },
                                                {
                                                    dataField: "createdAt",
                                                    text: "Date",
                                                    sort: false,
                                                    formatter: (cell, row) => {
                                                        return (cell ? moment(cell).format("MMMM DD, YYYY hh:mm A") : "-");
                                                    },
                                                }
                                            ]}
                                        />
                                    </CardBody>
                                </div>}
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    };
    return (
        <div>
            <div className="col-md-12 mx-auto mt-2">
             <BootstrapTable
                remote={{
                    sort: false,
                    pagination: true,
                    filter: true,
                    search: true
                }
                }
                wrapperClasses="table-responsive"
                keyField="_id"
                noDataIndication={() => <NoDataIndication />}
                data={(props.virtualVisitCallLogsData && props.virtualVisitCallLogsData.data) ? props.virtualVisitCallLogsData.data : []}
                pagination={options.page > 0 ? paginationFactory(options) : undefined}
                columns={columns}
                bordered
                onTableChange={props.pagination}
                expandRow={{
                    renderer: (row) =>
                        rowComponent(row),
                    onlyOneExpanding: false,
                    showExpandColumn: true,
                    expandByColumnOnly: false
                }}
            />
            </div>
        </div>
    )
}



export default VirtualCallLogsList;