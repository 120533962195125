const USERS_ROLE = [
  {
    "role": "Main Care Provider",
    "value": "careprovider",
    "type": "Careprovider"
  },
  {
    "role": "Office Manager",
    "value": "Office Manager",
    "type": "Staff"
  },
  {
    "role": "RPM Manager",
    "value": "RPM Manager",
    "type": "Staff"
  },
  {
    "role": "Staff",
    "value": "Staff",
    "type": "Staff"
  },
  // {
  //   "role": "Staff",
  //   "value": "Staff Member",
  //   "type": "Staff"
  // },
  {
    "role": "Patient Account",
    "value": "patient",
    "type": "Patient"
  },
  {
    "role": "Shared/Family Account",
    "value": "Shared/Family Account",
    "type": "Shared"
  },
  {
    "role": "Shared/Family Account",
    "value": "Shared/Family Account",
    "type": "Staff"
  },
  {
    "role": "Other",
    "value": "Other",
    "type": "Staff"
  }
]

const ROLES = {
  careprovider : 'careprovider',
  patient : 'patient',
  admin : 'admin'
}
  module.exports = {
    USERS_ROLE,
    ROLES
  }