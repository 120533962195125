// Implementation of axios client and interceptor for Encryptation and Decryptation Purpose

import axios from 'axios';
import _ from 'lodash';
import encryptRequest from '../helpers/encryptationHelper';
import decryptResponse from '../helpers/decryptationHelper';
import { queryStringToObject, objectToQueryString } from '../helpers/globalHelper';

// Axios Clinet
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_HOST_URL_PREFIX
})

// Request Interceptor To handle request data
// Used interceptors.request.use() to intercept and modify outgoing requests
axiosClient.interceptors.request.use(
    
    function (config) {
      try {
        // console.log("Making request using this url", config?.url);
        // Encrypt Each Filed
        const encryptObjectValues = (obj) => { return _.mapValues(obj, (value) => encryptRequest(value))};

        // Encrypt Query String Permaeter Before Making Request
        if(config.url.includes("?")){
            let queryParams = config.url.split("?")[1];
            let queryObject = queryStringToObject(queryParams);
            const encryptedQueryObject = encryptObjectValues(queryObject);
            let encryptedQueryParams = objectToQueryString(encryptedQueryObject);
            config.url = `${config.url.split("?")[0]}?${encryptedQueryParams}`
        }
        if (config?.data instanceof FormData) {
          const formData = new FormData();

          // Iterate through FormData entries and collect key-value pairs
          config.data.forEach((value, key) => {
            formData.append(key, (value instanceof File ? value : encryptRequest(value)));
          });
          config.data = formData;
        } else {
          // Encrypt Config Data
          if (config?.data) {
            const encryptData = encryptObjectValues(config?.data);
            config.data = encryptData;
          }
        }
        

      } catch (error) {
         console.log("request error:", error);
      }
      return config;
    },
    function (error) {
      // Handle the error
      console.log("Error:", error);
      return Promise.reject(error);
    }

);

// Response Interceptor To handle response data
// response interceptors in Axios provide the ability to handle responses globally
axiosClient.interceptors.response.use(
    function (response) {
        try {
            // console.log("this is response", response?.data);
            // Decrypt The Response After Sending as Main Response
            const decryptedData = decryptResponse(response.data);
            // console.log("this is decryptedData:", decryptedData);
            response.data = decryptedData;
            return response;
        } catch (error) {
            console.log("errr", error);
            return null;
        }
    },
    function (error) {
      
        console.log("error:", error);
      
      // Decrypt Error Data Before Send
      const decryptedData = decryptResponse(error.response?.data);
      error.response.data = decryptedData;

      return Promise.reject(error);
    }
);


export default axiosClient;

