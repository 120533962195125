import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import { timeFormate } from "../../constants/general.constant";
import UserName from "../Reusable/UserName";
import {
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
} from "reactstrap";
const _ = require("lodash");

function PrintableContent(data) {
    function fullname(cell, row) {
        return <UserName user={row}></UserName>;
    }
    const cptCodeDisplay = (cell) => {
        let CPTCodeA;
        let CPTCodeB;
        let CPTCodeC;
        let CPTCodeD;
        // CPT Code A list display
        if (cell && cell.CPTCodeA && cell.CPTCodeA.length > 0) {
            CPTCodeA = []
            _.map(cell.CPTCodeA, (value, index) => {
                CPTCodeA.push(
                    <div key={`key-${index}-CPTCodeA`}>
                        <button
                            className="btn btn-sm btn-info session-code-button-radial"
                            id={`btn-${index}-CPTCodeA`} // Ensure button has a unique ID
                        >
                            {value}
                        </button>
                        <UncontrolledPopover
                            trigger="legacy"
                            placement="top"
                            target={`btn-${index}-CPTCodeA`} // Use the ID for the popover target
                            delay={{ show: 100, hide: 100 }} // Add a slight delay to ensure the element is in the DOM
                        >
                            <PopoverHeader>Description</PopoverHeader>
                            <PopoverBody>
                                There has been an attestation for a virtual call between 5 and 10 minutes with the care provider.
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                );
            });
        }

        // CPT Code B list display
        if (cell && cell.CPTCodeB && cell.CPTCodeB.length > 0) {
            CPTCodeB = []
            _.map(cell.CPTCodeB, (value, index) => {
                CPTCodeB.push(
                    <div key={`key-${index}-CPTCodeB`}>
                        <button
                            className="btn btn-sm btn-warning session-code-button-radial text-white"
                            id={`btn-${index}-CPTCodeB`} // Ensure button has a unique ID
                        >
                            {value}
                        </button>
                        <UncontrolledPopover
                            trigger="legacy"
                            placement="top"
                            target={`btn-${index}-CPTCodeB`} // Use the ID for the popover target
                            delay={{ show: 100, hide: 100 }} // Add a slight delay to ensure the element is in the DOM
                        >
                            <PopoverHeader>Description</PopoverHeader>
                            <PopoverBody>
                                There has been an attestation for a virtual call between 10 and 20 minutes with the care provider.
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                );
            });
        }

        // CPT Code C list display
        if (cell && cell.CPTCodeC && cell.CPTCodeC.length > 0) {
            CPTCodeC = []
            _.map(cell.CPTCodeC, (value, index) => {
                CPTCodeC.push(
                    <div key={`key-${index}-CPTCodeC`}>
                        <button
                            className="btn btn-sm btn-primary session-code-button-radial text-white"
                            id={`btn-${index}-CPTCodeC`} // Ensure button has a unique ID
                        >
                            {value}
                        </button>
                        <UncontrolledPopover
                            trigger="legacy"
                            placement="top"
                            target={`btn-${index}-CPTCodeC`} // Use the ID for the popover target
                            delay={{ show: 100, hide: 100 }} // Add a slight delay to ensure the element is in the DOM
                        >
                            <PopoverHeader>Description</PopoverHeader>
                            <PopoverBody>
                                There has been an attestation for a virtual call between 5 and 10 minutes with the staff.
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                );
            });
        }

        // CPT Code D list display
        if (cell && cell.CPTCodeD && cell.CPTCodeD.length > 0) {
            CPTCodeD = []
            _.map(cell.CPTCodeD, (value, index) => {
                CPTCodeD.push(
                    <div key={`key-${index}-CPTCodeD`}>
                        <button
                            className="btn btn-sm btn-success session-code-button-radial text-white"
                            id={`btn-${index}-CPTCodeD`} // Ensure button has a unique ID
                        >
                            {value}
                        </button>
                        <UncontrolledPopover
                            trigger="legacy"
                            placement="top"
                            target={`btn-${index}-CPTCodeD`} // Use the ID for the popover target
                            delay={{ show: 100, hide: 100 }} // Add a slight delay to ensure the element is in the DOM
                        >
                            <PopoverHeader>Description</PopoverHeader>
                            <PopoverBody>
                                There has been an attestation for a virtual call between 10 and 20 minutes with the staff.
                            </PopoverBody>
                        </UncontrolledPopover>
                    </div>
                );
            });
        }

        if (CPTCodeA || CPTCodeB || CPTCodeC || CPTCodeD) {
            return (
                <div className="mx-auto text-center">
                    {CPTCodeA} {CPTCodeB}
                    {CPTCodeC} {CPTCodeD}
                </div>
            );
        } else {
            return ('-');
        }
    }
    let columns = [
        {
            dataField: "fullNamel",
            text: "Last, First Name",
            // formatter: fullname,
            formatter: fullname,
        },
        {
            dataField: "nickname",
            text: "Nickname",
            // formatter: fullname,
            formatter: (cell, row) => {
                return (row.nickname ? row.nickname : "-");
            },
        },
        {
            dataField: "bday",
            text: "DOB",
            // formatter: fullname,
            formatter: (cell, row) => {
                return moment(cell).format("MMMM DD, YYYY");
            },
        },

        {
            dataField: "createdAt",
            text: "Enrolled Date",
            formatter: (cell, row) => {
                return moment(cell).format("MMMM DD, YYYY");
            },
        },
        {
            dataField: "mainCareProvider",
            text: "Main Care Provider",
            sort: false,
            formatter: (cell, row) => {
                return (cell ? cell.lname + " " + cell.fname : "-");
            },
        },
        {
            dataField: "organization",
            text: "Organization",
            formatter: (cell, row) => {
                return (cell ? cell : "-");
            },
        },
        {
            dataField: "cptCodeData",
            text: "Careprovider Call Duration",
            sort: false,
            formatter: (cell, row) => {
                if (cell && cell.careProviderCallTotalDuration) {
                    return timeFormate(cell.careProviderCallTotalDuration);
                } else {
                    return "00:00:00"
                }
            },
        },
        {
            dataField: "cptCodeData",
            text: "Staff Call Duration",
            sort: false,
            formatter: (cell, row) => {
                if (cell && cell.staffCallTotalDuration) {
                    return timeFormate(cell.staffCallTotalDuration);
                } else {
                    return "00:00:00"
                }
            },
        },
        {
            dataField: "cptCodeData",
            text: "Billable CPT Code(s)",
            sort: false,
            formatter: (cell, row) => {
                return cptCodeDisplay(cell)
            },
        },
    ]
    return (
        <BootstrapTable
            keyField="_id"
            data={data}
            columns={columns}
        />
    )
}

const VirtualCallLogsPrint = React.forwardRef(({ data }, ref) => {
    return (
        <div className='padding-card-body' ref={ref}>
            {PrintableContent(data)}
        </div>
    );
});
export default VirtualCallLogsPrint;