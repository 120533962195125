import moment from "moment";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import AdherenceIcon from "../../files/adherence.svg";
import ReminderIcon from "../../files/reminder.jpg";
import TimeIcon from "../../files/timeadherence.jpg";
import TrendingIcon from "../../files/trending.svg";
import userImage from "../../files/user.png";
import VitalsIcon from "../../files/vitals.jpg";
import WeightIcon from "../../files/weight.svg";
import QuestionnaireIcon from "../../files/questionnaire.svg";
import '../../css/css/style.css';
import Link from "components/ExtendLink";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row } from "reactstrap";
import LottieLoader from '../LottieLoader';
import lottieEmptyFile from '../../files/animation/empty_new.json';
import lottieLoaderFile from '../../files/animation/loading.json';
import { _ } from "lodash";
function withIcon(cell) {
  let icon;
  switch (cell) {
    case "Vitals":
      icon = VitalsIcon;
      break;
    case "Time Adherence":
      icon = TimeIcon;
      break;
    case "Adherence":
      icon = AdherenceIcon;
      break;
    case "Reminder":
      icon = ReminderIcon;
      break;
    case "Trending Violation":
      icon = TrendingIcon;
      break;
    case "Weight":
      icon = WeightIcon;
      break;
    case "Questionnaire":
      icon = QuestionnaireIcon;
      break;
    default:
      break;
  }
  return (
    <span>
      <img src={icon} className="mr-2" alt="image" />
      {cell}
    </span>
  );
}
const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <Row>
      <Col>
        <button className="btn btn-info ml-2 download-click-cls" onClick={handleClick}>
          <i className="mdi mdi-download" /> Export to CSV
        </button>
      </Col>
    </Row>
  );
};
function fullname(cell) {
  if(cell) {
    const profileImage = cell.profileImage ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + cell.profileImage : userImage;
    return (
      <div>
        <img src={profileImage} className="mr-2" alt="image" />
        {cell.fname} {cell.lname} {cell.mainCareProvider ? '(' + cell.mainCareProvider.fname +' '+ cell.mainCareProvider.lname +')': ''}
      </div>
    );
  }else {
    return "";
  }
}
function nickname(row) {
  if(row) {
    return (
      <div>
        {row ? row : '-'}
      </div>
    );
  }else {
    return "-";
  }
}
function formatDate(cell) {
  return moment(cell).format("MMMM DD, YYYY hh:mm A");
}
function priceFormatter(column, colIndex, { sortElement, filterElement }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span className="mb-2">{filterElement}</span>

      {column.text}
      {sortElement}
    </div>
  );
}

const vitalReading = (cell, row) => {
  if(row){
    if (cell ||row.ruleType =="Questionnaire" || row.ruleType == "Trending Violation") {
      const { violatedBy, createdBy } = row;
      if (createdBy && row.ruleType !="Questionnaire") {
        return (
          <Link
            to={`/violation/details?identifier=${createdBy._id}tldr${violatedBy._id}&vuid=${row._id}`}
            className="text-white ml-auto btn btn-sm btn-success btn-rounded btn-fw"
          >
            More Details
          </Link>
        );
      }
      if (row.ruleType =="Questionnaire") {
        return (
          <Link
            to={`/violation/questionnairedetails?identifier=${createdBy._id}tldr${violatedBy._id}&vuid=${row._id}`}
            className="text-white ml-auto btn btn-sm btn-success btn-rounded btn-fw"
          >
            More Details
          </Link>
        );
      }
    }
  }  
  return "";
};
const ViolationTable = (props, context) => {
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" "}
      Showing {from} to {to} of {size} Results
    </span>
  );
  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "10",
        value: 10
      },
      {
        text: "20",
        value: 20
      },
      {
        text: "All",
        value: props.finalViolations.length
      }
    ]
  };
  const NoDataIndication = () => (
    <div>
      {props.loading && (
        <LottieLoader
          loadLoop={true}
          autoplayLoop={true}
          source={lottieLoaderFile}
          Lheight={150}
          Lwidth={150}
          subTitle={"Please Wait ..."}
        />
      )}
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No "+props.displayMessaeg+" found :("}
          subTitle={"We can't find any "+props.displayMessaeg+"."}
        />
      )}              
    </div>
  );
  return (
    <div>
      {props.loading &&
        <LottieLoader
        loadLoop={true}
        autoplayLoop={true}
        source={lottieLoaderFile} 
        Lheight={150}
        Lwidth={150}
        subTitle={"Please Wait ..."}
      />
      }
      {!props.loading &&
      <ToolkitProvider
          keyField="_id"
          data={props.finalViolations}
          columns={[
            {
              dataField: "name",
              text: "Title",
              sort: true,
              filter: textFilter({
                className: 'input-date-filter',
                placeholder: 'Search by title'
              }),
              headerFormatter: priceFormatter,
              formatter: (cell, row) => {
                if (row && row.isRead) {
                  return row.name;
                } else {
                  if(row){
                    return <strong>{row.name}</strong>;
                  }else{
                    return <strong></strong>;
                  }
                }
              },
              headerStyle: {
                width: '250px' // Set your desired width
              },
              style: {
                width: '250px' // Set your desired width
              }
            },
            ...(props.display !== 'Questionnaires' ? [{
              dataField: "readingSubType",
              text: "Reading Type",
              sort: true,
              filter: textFilter({
                className: 'input-date-filter',
                placeholder: "Search by reading type",
              }),
              headerFormatter: priceFormatter,
              headerStyle: {
                width: '250px' // Set your desired width
              },
              style: {
                width: '250px' // Set your desired width
              }
            }] : []),
            ...(props.display !== 'Questionnaires' ? [{
              dataField: "ruleType",
              text: "Rule Type",
              sort: true,
              filter: textFilter({
                className: 'input-date-filter',
                placeholder: "Search by rule type"
              }),
              formatter: withIcon,
              headerFormatter: priceFormatter,
              headerStyle: {
                width: '250px' // Set your desired width
              },
              style: {
                width: '250px' // Set your desired width
              }
            }] : []),
            {
              dataField: "violatedBy",
              text: "Patient",
              sort: true,
              filter: textFilter({
                className: 'input-date-filter',
                placeholder: "Search by Patient Name",
              }),
              formatter: fullname,
              filterValue: (cell, row) => {
                return cell.fname + " " + cell.lname + " " + (cell.mainCareProvider ? cell.mainCareProvider.fname +' '+ cell.mainCareProvider.lname : '')
              },
              headerFormatter: priceFormatter,
              csvFormatter: (cell, row) => {
                return (
                  cell.fname +
                  " " +
                  cell.lname +
                  (cell.mainCareProvider ? '(' + cell.mainCareProvider.fname +' '+ cell.mainCareProvider.lname +')': '')
                );
              },
              headerStyle: {
                width: '250px', // Set your desired width
              },
              style: {
                width: '250px', // Set your desired width
              },
              csvExport: true
            },
            {
              dataField: "violatedBy.nickname",
              text: "Patient Nickname",
              sort: true,
              filter: textFilter({
                className: 'input-date-filter',
                placeholder: "Search by Patient Nickname"
              }),
              formatter: nickname,
              filterValue: (cell, row) => {
                return row.violatedBy.nickname ? row.violatedBy.nickname : "-";
              },
              headerFormatter: priceFormatter,
              csvFormatter: (cell, row) => {
                return row.violatedBy.nickname ? row.violatedBy.nickname : "-";
              },
              headerStyle: {
                width: '190px' // Set your desired width
              },
              style: {
                width: '190px' // Set your desired width
              }
            },
            {
              dataField: "createdAt",
              text: "Occurred",
              sort: true,
              formatter: formatDate,
              headerFormatter: priceFormatter,
              csvFormatter: (cell, row) => {
                return moment(cell).format("MMMM DD, YYYY hh:mm A");
              },
              headerStyle: {
                width: '150px' // Set your desired width
              },
              style: {
                width: '150px' // Set your desired width
              }
            },
            {
              dataField: "reading",
              text: "Details",
              formatter: vitalReading,
              headerFormatter: priceFormatter,
              csvExport: false,
              headerStyle: {
                width: '150px' // Set your desired width
              },
              style: {
                width: '150px' // Set your desired width
              }
            }
          ]}
          exportCSV={{ 
            fileName: `${props.display}.csv`,
            onlyExportFiltered: true, // Ensures only filtered data is exported
            exportAll: false, // Disables exporting all data by default
          }}
        >
          {(toolKitProp) => (
            <div>
              <Row className="float-right">
                <Col>
                  <MyExportCSV {...toolKitProp.csvProps}>Export CSV</MyExportCSV>
                </Col>
              </Row>

              <BootstrapTable
                wrapperClasses="table-responsive"
                {...toolKitProp.baseProps}
                noDataIndication={() => <NoDataIndication />}
                hover={true}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
              />
            </div>
          )}
        </ToolkitProvider>
      }    
    </div>  
  );
};

export default ViolationTable;
