import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
    Nav,
    NavItem,
    NavLink,
    Row,
    FormGroup,
    Label,
    Col,
} from "reactstrap";
import classnames from "classnames";
import VirtualCallLogsList from "./virtualCallLogsList";
import moment from "moment";
import Select from "react-select";
import { months, years } from "../../constants/general.constant";
import { useReactToPrint } from "react-to-print";
import VirtualCallLogsPrint from "./virtualVisitCallLogsPrint";

function VirtualVisitCallLogs(props) {
    const contentRef = useRef();
    // Correct use of useReactToPrint hook
    const reactToPrintFn = useReactToPrint({
        content: () => contentRef.current, // Referencing content to print
        documentTitle: "Virtual Visit Logs",
    });

    const [careProvider, setCareProvider] = useState(null);
    const [startDate, setStartDate] = useState(moment().startOf("month").format());
    const [endDate, setEndDate] = useState(moment().endOf("month").format());
    const [search, setSearch] = useState(null)
    const [timer, setTimer] = useState(null);
    const [selectedMonth, setMonth] = useState(moment().month() + 1);
    const [selectedYear, setYear] = useState(moment().year());
    const [printRequest, setPrintRequest] = useState(false);
    
    useEffect(() => {
        props.dispatch({
            type: "DO_GET_VIRTUAL_CALL_LOGS", // replace with your actual action type
            payload: {
            startDate : moment().startOf("month").format(),
            endDate : moment().endOf("month").format()
            }
        });
        props.dispatch({
            type: "DO_GET_CAREPROVIDER",
            payload: ""
        });
    }, []); // Empty array ensures the effect runs only once
     // Trigger the print once data is ready
    useEffect(() => {
    if (!props.virtualVisitCallLogsPrintDataRequest && printRequest) {
      reactToPrintFn(); // Trigger print only when data is ready
      setPrintRequest(false);
    }
    }, [props.virtualVisitCallLogsPrintDataRequest]);
 
    useEffect(() => {
    const query = {}
    if(search){
    query.search = search;
    }
    submitFilter(query)
    }, [careProvider, startDate, endDate])
    const customStyles = {
        control: base => ({
            ...base,
            height: 40,
            minHeight: 40
        })
    };
    const handleExportCsv = () => {
      const query = {}
      if (careProvider) {
          query.mainCareProvider = careProvider
      }
      if (startDate) {
          query.startDate = startDate
      }
      if (endDate) {
          query.endDate = endDate
      }
      props.dispatch({
          type: "DO_GET_VIRTUAL_CALL_LOGS_EXPORT",
          payload: query
      });
    }
    const handleExportCsvLogs = (data) => {
      if (data) {
          props.dispatch({
              type: "DO_GET_VIRTUAL_CALL_LOGS_EXPORT",
              payload: {
                  patientId: data._id
              }
          });
      }
    }
    const handleCareprovider = (event) => {
        if (event) {
            setCareProvider(event._id);
        }
    }
    const handleMonthChange = (event) => {
        if(event && event.value){
        const monthIndex = parseInt(event.value, 10) - 1;
        setStartDate(moment().month(monthIndex).startOf('month').format());
        setEndDate(moment().month(monthIndex).endOf("month").format());
        setMonth(event.value)
        }
    }
    const handleYearChange = (event) => {
        if(event && event.value){
        setStartDate(moment().year(event.value).startOf('month').format());
        setEndDate(moment().year(event.value).endOf('month').format())
        setYear(event.value)
        }
    }
    const clearFilter = () => {
        setCareProvider(null);
        setEndDate(moment().endOf("month").format());
        setStartDate(moment().startOf("month").format());
        setMonth(moment().month() + 1)
        setYear(moment().year())
        setSearch('');
        props.dispatch({
        type: "DO_GET_VIRTUAL_CALL_LOGS", // replace with your actual action type
        payload: {
        startDate : moment().startOf("month").format(),
        endDate : moment().endOf("month").format()
        }
        });
    }
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearch(value);
        if (timer) {
            clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
            if (value) {
                submitFilter({ search: value })
            } else {
                setSearch('');
                submitFilter({ search: null })
            }
        }, 500); // 500ms delay after typing stops

        setTimer(newTimer);
    }
    const handlePagination = (event, { page, sizePerPage, filters, sortField, sortOrder }) => {
        let data = {
            page: page, limit: sizePerPage
        }
        if (sortOrder) {
            data.sortOrder = sortOrder
        }
        if (sortField) {
            data.sortField = sortField
        }
        submitFilter(data)
    }
    const submitFilter = (data) => {
        const query = {}
        if (careProvider) {
            query.mainCareProvider = careProvider
        }
        if (startDate) {
            query.startDate = startDate
        }
        if (endDate) {
            query.endDate = endDate
        }
        if (data && data.page) {
            query.page = data.page;
        }
        if (data && data.sortField) {
            query.sortField = data.sortField;
        }
        if (data && data.limit) {
            query.limit = data.limit;
        }
        if (data && data.sortOrder) {
            query.sortOrder = data.sortOrder;
        }
        if (data && data.search) {
            query.search = data.search;
        }
        props.dispatch({
            type: "DO_GET_VIRTUAL_CALL_LOGS", // replace with your actual action type
            payload: query // replace with actual payload if needed
        });
    }
    
    const handlePrint = async () => {
      const query ={
        getAllData : true,
      }
      if (careProvider) {
        query.mainCareProvider = careProvider
      }
      if (startDate) {
          query.startDate = startDate
      }
      if (endDate) {
          query.endDate = endDate
      }
      props.dispatch({
        type: "DO_GET_VIRTUAL_CALL_LOGS_PRINT", // replace with your actual action type
        payload: query // replace with actual payload if needed
      });
      setPrintRequest(true)
    }
    return (
      <div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <Nav tabs className="justify-content-center">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: true,
                    })}
                  >
                    Virtual visit calls
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="card-body project-tab pt-2">
                <Row className="text-center col-md-12 mt-5 pm-session-title-margin mx-auto">
                  <Col className="notes-pdf-title">
                    <h5 className="mb-4 pm-session-title">
                      Choose a date range to calculate the total virtual visit
                      call duration.{" "}
                    </h5>
                  </Col>
                  <Col className="notes-pdf-details">
                    <table>
                      <tbody>
                        <tr>
                          <td style={{ textAlign: "start", fontSize: 16 }}>
                            {" "}
                            <i
                              class="mdi mdi-calendar"
                              style={{ color: "#ff5722" }}
                            ></i>{" "}
                            Start Date :
                          </td>
                          <td
                            style={{
                              fontWeight: 600,
                              textAlign: "start",
                              fontSize: 16,
                            }}
                          >
                            {"\u00a0\u00a0"}
                            {moment(startDate).format("LL")}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ textAlign: "start", fontSize: 16 }}>
                            <i
                              class="mdi mdi-calendar"
                              style={{ color: "#ff5722" }}
                            ></i>{" "}
                            End Date :
                          </td>
                          <td
                            style={{
                              fontWeight: 600,
                              textAlign: "start",
                              fontSize: 16,
                            }}
                          >
                            {"\u00a0\u00a0"}
                            {moment(endDate).format("LL")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <Row
                  className="mx-auto"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Col md={2}>
                    <FormGroup>
                      <Label for="exampleCity">Pick the Month</Label>
                      <Select
                        onChange={handleMonthChange}
                        options={months}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        styles={customStyles}
                        value={months.find((month) => month.value == selectedMonth)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="exampleEmail">Pick the Year</Label>
                      <Select
                        onChange={handleYearChange}
                        options={years}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        styles={customStyles}
                        value={years.find((year) => year.value === selectedYear)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="exampleSelect">Careprovider</Label>
                      <Select
                        required={true}
                        value={
                          careProvider
                            ? props.careProviderAll.find(
                                (item) => item._id === careProvider
                              )
                            : ""
                        }
                        onChange={(event) => handleCareprovider(event)}
                        options={
                          props.careProviderAll ? props.careProviderAll : []
                        }
                        isMulti={false}
                        getOptionLabel={(option) =>
                          option["fname"] + " " + option["lname"]
                        }
                        getOptionValue={(option) => option}
                        styles={customStyles}
                        name="careProvider"
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    lg={3}
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <button
                      type="button"
                      className="btn btn-info close-click-cls ml-3 mt-4 p-0 d-flex align-items-center justify-content-center"
                      style={{ width: 150, height: 37 }}
                      disabled={
                        careProvider || search
                          ? false
                          : true
                      }
                      onClick={() => clearFilter()}
                    >
                      <i className="mdi mdi-close" style={{ fontSize: 20 }}></i>
                      CLEAR FILTERS
                    </button>
                  </Col>
                </Row>
                <Row className="mx-auto">
                <Col md={9} sm={9} lg={9}>
                <div class="form-group" style={{ marginTop: "5px" }}>
                <input
                  type="text"
                  class="form-control input-date-filter remove-bottom-margin"
                  id="exampleInputName1Test"
                  style={{ fontSize: "20px" }}
                  placeholder="Search Last Name, First Name, Organization, Main care Provider..."
                  onChange={(event) => handleSearch(event)}
                  value={search}
                />
                </div>
                </Col>
                <Col md={3} sm={3} lg={3} style={{display : 'flex'}}>
                <button
                style={{ marginTop: 5, width: 164, height: 45 }}
                className="btn btn-info download-click-cls"
                onClick={() => handleExportCsv()}
                disabled={props.virtualVisitCallLogsExportRequest ? true : props.ongoingVirtualVisitCallRequest ? true : (props.virtualVisitCallLogs && props.virtualVisitCallLogs.data && props.virtualVisitCallLogs.data.length == 0) ? true : false }
                >
                {props.virtualVisitCallLogsExportRequest ? <i className="mdi mdi-loading mdi-spin mr-2" style={{ fontSize: 20 }}></i> : <i className="mdi mdi-download" style={{ fontSize: 20 }}></i>}
                Export to CSV
                </button>
                <button
                className="btn btn-info print-click-cls"
                style={{ marginTop: 5, width: 164, height: 45 }}
                onClick={() => handlePrint()}
                disabled={props.virtualVisitCallLogsPrintDataRequest}
                >
                <i className="mdi mdi-cloud-print"></i>
                {props.virtualVisitCallLogsPrintDataRequest ? 'Loading...' : 'Print'}
                </button>
                </Col>
                </Row>
                <Row>
                  <Col md={12} sm={12} lg={12}>
                    <div className="hide">
                    <VirtualCallLogsPrint ref={contentRef} data={props.virtualVisitCallLogsPrintData && props.virtualVisitCallLogsPrintData.data ? props.virtualVisitCallLogsPrintData.data : []} />
                    </div>
                    {props.ongoingVirtualVisitCallRequest ? (
                      <VirtualCallLogsList
                        virtualVisitCallLogsData={null} // pass real data if needed
                        loading={true} // change to a real loading state if required
                      />
                    ) : (
                      <VirtualCallLogsList
                        virtualVisitCallLogsData={
                          props.virtualVisitCallLogs
                            ? props.virtualVisitCallLogs
                            : {}
                        } // pass real data if needed
                        loading={false} // change to a real loading state if required
                        virtualVisitCallLogsExportRequest={
                          props.virtualVisitCallLogsExportRequest
                        }
                        virtualVisitCallLogsExport={
                          props.virtualVisitCallLogsExport
                        }
                        props={props}
                        exportCSV={handleExportCsvLogs}
                        pagination={handlePagination}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = ({ careprovider, user }) => {
    const {
        ongoingVirtualVisitCallRequest,
        virtualVisitCallLogs,
        careProviderAll,
        virtualVisitCallLogsExportRequest,
        virtualVisitCallLogsExport,
        virtualVisitCallLogsPrintData,
        virtualVisitCallLogsPrintDataRequest
    } = careprovider;
    return {
        ongoingVirtualVisitCallRequest,
        virtualVisitCallLogs,
        careProviderAll,
        virtualVisitCallLogsExportRequest,
        virtualVisitCallLogsExport,
        virtualVisitCallLogsPrintData,
        virtualVisitCallLogsPrintDataRequest
    };
};

export default connect(mapStateToProps)(VirtualVisitCallLogs);
