import { useEffect, useRef, useState } from "react";

import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { ZIM } from "zego-zim-web";
import userImage from "../../files/user.png";
import moment from "moment";
import outgoingRingtoneFile from "../../files/ringtone/outgoing_ringtone.mp3";

const Swal = require("sweetalert2");
const _ = require("lodash");

export default function VideoCallComponent(props) {
  const { patient, careprovider = "", videoCallCount } = props;
  let [callStartTime, setCallStartTime] = useState(null);
  const zeroCloudInstance = useRef(null);
  const outgoingRingtone = useRef(new Audio(outgoingRingtoneFile));

  useEffect(() => {
    if (patient && careprovider) {
      const patientUserID = patient._id;
      const patientUserName = `${patient.fname} ${patient.lname}`;
      const currantUserID = careprovider._id;
      const currantUserName = `${careprovider.fname} ${careprovider.lname}`;
      const profile = patient.profileImage
        ? process.env.REACT_APP_VIDEO_CALL_REQUEST + "/" + patient.profileImage
        : userImage;
      const KitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        parseInt(process.env.REACT_APP_ZEGO_CLOUD_APP_ID),
        process.env.REACT_APP_ZEGO_CLOUD_APP_SECRET,
        null,
        currantUserID,
        currantUserName
      );

      zeroCloudInstance.current = ZegoUIKitPrebuilt.create(KitToken);
      zeroCloudInstance.current.addPlugins({ ZIM });
      // Set up event listeners for call events
      zeroCloudInstance.current.setCallInvitationConfig({
        ringtoneConfig: {
          outgoingCall: "../../files/ringtone/outgoing_ringtone.mp3",
        },
        onOutgoingCallAccepted: (callID, callee) => {
          outgoingRingtone.current.pause();
          // Record call start time when the outgoing call is accepted
          callStartTime = moment.utc();
        },
      });

      const inviteCall = () => {
        // Play the outgoing ringtone
        outgoingRingtone.current.loop = true; // Loop ringtone
        outgoingRingtone.current
          .play()
          .catch((err) => console.log("Ringtone play error:", err));

        zeroCloudInstance.current
          .sendCallInvitation({
            callees: [
              {
                userID: patientUserID,
                userName: patientUserName,
                avatar: profile,
              },
            ],
            callType: ZegoUIKitPrebuilt.InvitationTypeVideoCall,
            timeout: 60,
            data: "Incoming Call",
            notificationConfig: {
              resourcesID: "MyVitalView",
              title: "Call invitation",
              message: "Incoming video call...",
              android: {
                channelId: "zego_call_channel",
                importance: "high",
              },
            },
          })
          .then((res) => {
            if (res.errorInvitees.length) {
              outgoingRingtone.current.pause(); // Stop ringtone if call fails
              Swal.fire({
                title: "User Offline!",
                text: "The user does not exist or is offline!",
                icon: "error",
              });
            }
          })
          .catch((err) => {
            outgoingRingtone.current.pause(); // Stop ringtone on error
            try {
              const parsedData = JSON.parse(err);
              switch (parsedData?.code) {
                case 6000121:
                case 6000281:
                  Swal.fire({
                    title: "Patient Unavailable",
                    text: "The patient is currently unavailable or has logged out of the app. Patient is logged in, they will be able to join the video call!",
                    icon: "info",
                  });
                  break;
                default:
                  const message = parsedData.code;
                  Swal.fire({
                    title: "Something want wrong!",
                    text: message,
                    icon: "error",
                  });
                  break;
              }
              // Proceed with using parsedData if no error
            } catch (error) {
              console.error("Error parsing JSON:", error);
              // Handle the error (e.g., fallback logic or user notification)
            }
          });
      };

      setTimeout(inviteCall, 2000);

      zeroCloudInstance.current.setCallInvitationConfig({
        endCallWhenInitiatorLeave: true,
        showRoomTimer:true,
        showScreenSharingButton : false,
        showUserList: false, // Hides the user list (removes member icons)
        onWaitingPageWhenSending: (callType, callees, cancel) => {
          setCallStartTime(Date.now());
        },
        onSetRoomConfigBeforeJoining: (callType) => {
          setCallStartTime(Date.now());
          return {
            autoLeaveRoomWhenOnlySelfInRoom: true,
            showUserList:false
          };
        },
        onCallInvitationEnded: (event) => {
          outgoingRingtone.current.pause();
          const result = _.set([], "[0]", {
            participant: patientUserID,
          });
          const model = {
            createdBy: currantUserID,
            participants: result,
            status: event,
          };
          switch (event) {
            case "Declined":
              props.openVideoCallNoteModal(model, false);
              Swal.fire({
                title: "Call Declined",
                text: "The patient has declined your call invitation.",
                icon: "warning",
              });
              break;
            case "Timeout":
              props.openVideoCallNoteModal(model, false);
              Swal.fire({
                title: "Call Timed Out",
                text: "The call invitation expired due to no response within the virtual visit session time limit.",
                icon: "error",
              });
              break;
            case "Busy":
              props.openVideoCallNoteModal(model, false);
              Swal.fire({
                title: "User is Busy",
                text: "The patient is currently busy and unable to join the call.",
                icon: "info",
              });
              break;
            case "Canceled":
              props.openVideoCallNoteModal(model, false);
              Swal.fire({
                title: "Call Canceled",
                text: "The call invitation was canceled by the caller before it was answered.",
                icon: "error",
              });
              break;

            case "LeaveRoom":
              if (callStartTime) {
                const callEndTime = moment.utc();
                const duration = Math.floor(
                  (callEndTime - callStartTime) / 1000
                );
                console.log(`Call duration: ${duration} seconds`);
                // Creating an array with a child object
                const result = _.set([], "[0]", {
                  participant: patientUserID,
                  joinedAt: callStartTime,
                  leftAt: callEndTime,
                });
                const model = {
                  duration: duration,
                  startedAt: callStartTime,
                  endedAt: callEndTime,
                  createdBy: currantUserID,
                  participants: result,
                };
                Swal.fire({
                  title: "Call Ended",
                  text: "The call has ended. You or the patient has left the call.",
                  icon: "error",
                }).then(() => {
                  // This will run only after the Swal alert is closed
                  props.openVideoCallNoteModal(model, true);
                });
              }
              break;
          }
        },
      });
    }

    return () => {
      if (zeroCloudInstance.current) {
        zeroCloudInstance.current = null;
      }
    };
  }, [videoCallCount]);

  return null;
}
