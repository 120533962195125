import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import Heading from "./Heading";
import SharedList from "./SharedList";
import CreateStaffModal from "./CreateStaffModal";
import familyAccount from "../../../files/ic_family_account.svg";
import { USERS_ROLE } from "../../../constants/role.constant";
import moment from "moment";
const _ = require("lodash");
class AdminSharedAccount extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      modal: false,
      modal2: false,
      modalStaff: false,
      emailOffer: "",
      emailAccess: "",
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      showAlertSuccess: false,
      errorPassword: false,
      role: "",
      otherRole: undefined,
      isOtherRole: false,
      page: 1,
      limit: 10,
      search: "",
      selectedOption: "",
      link: undefined
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleStaff = this.toggleStaff.bind(this);
    this.openInvite = this.openInvite.bind(this);
    this.submit = this.submit.bind(this);
    this.submitStaff = this.submitStaff.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.deleteInvitation = this.deleteInvitation.bind(this);
    this.userInputChange = this.userInputChange.bind(this);
    this.handleRole = this.handleRole.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleCareprovider = this.handleCareprovider.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleAllPatients = this.handleAllPatients.bind(this);
    this.handleAllAssignedCareproviders = this.handleAllAssignedCareproviders.bind(this);
    this.handleClearAllAssignedCareprovider = this.handleClearAllAssignedCareprovider.bind(this);
  }
  MyExportCSV = (props) => {
    const handleClick = () => {
      this.props.dispatch({
        type: "DO_REMOVE_PATIENT_LINK_PATH",
        payload: undefined
      });
      this.props.dispatch({
        type: "DO_EXPORT_SHARED_ACCOUNT_LIST",
      });
    };
    return (
      <Row>
        <Col>
          <button 
          style={{ marginTop: 5,width:164, height:43 }} 
          className="btn btn-info ml-2 download-click-cls"
          onClick={handleClick}>
            <i class="mdi mdi-download" style={{
                fontSize: 20
              }}></i> Export to CSV
          </button>
        </Col>
      </Row>
    );
  };
  MySearch = () => {
    let input;
    const handleChange = (e) => {
      e.preventDefault();
      const val = e.target.value
      this.setState({
        page: 1,
          search: val,
          sortField: "",
          sortOrder: "",
      });
    };
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        this.fetchData();        
      }, 200)
      // if this effect run again, because `value` changed, we remove the previous timeout
      return () => clearTimeout(timeout)
    }, [this.state.search])
    return (
      <div>
        <div class="form-group">
          <input
            type="text"
            class="form-control input-date-filter remove-bottom-margin"
            id="exampleInputName1Test"
            style={{ fontSize: "20px" }}
            placeholder="Search Name, Nickname, Email, etc..."
            ref={(n) => (input = n)}
            value={this.state.search}
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };
  handleKeyDown(e){
    const inputField = e.target;
    const inputValue = inputField.value;
    const caretPosition = inputField.selectionStart;
    if (e.keyCode === 32) {
      // Check if it's the first character and if there are no non-space characters before it
      if (caretPosition === 0 || /^\s+$/.test(inputValue.substring(0, caretPosition))) {
        e.preventDefault();
      }
    }
  };
  handleTableChange(type, { page, sizePerPage, filters, sortField, sortOrder }) {
    var sortNewOrder;
    if(this.state.sortOrder == "desc"){
      sortNewOrder = "asc"
      this.setState({
        page: page, limit: sizePerPage, sortField: sortField, sortOrder: sortNewOrder
      }, () => {
        this.fetchData();
      });
    }else{
      sortNewOrder = "desc"
      this.setState({
        page: page, limit: sizePerPage, sortField: sortField, sortOrder: sortNewOrder
      }, () => {
        this.fetchData();
      });
    }
  }
  handleAllPatients(staffId){
    this.props.dispatch({
      type: "DO_GET_ALL_STAFF_PATIENT_LIST",
      payload: { staffId: staffId },
    });
  }
  handleAllAssignedCareproviders(staffId){
    this.props.dispatch({
      type: "DO_GET_ADDITIONAL_CAREPROVIDER_LIST",
      payload: { staffId: staffId },
    });
    setTimeout(() => {
      this.setState({ selectedOption: this.props.userAssignedCareprovider });
    }, 500);
  }
  userInputChange(event, staffId,isSelected) {
    const target = event;
    if (isSelected) {
      this.props.dispatch({
        type: "DO_ADD_OR_DELETE_STAFF_PATIENT",
        payload: { staffId: staffId, isAdd: true, users: target },
      });
    } else {
      if(event.length < 2){
        this.props.dispatch({
          type: "DO_ADD_OR_DELETE_STAFF_PATIENT",
          payload: { staffId: staffId, isAdd: false, user: target },
        });
      }else{
        this.props.dispatch({
          type: "DO_DELETE_ALL_STAFF_PATIENT",
          payload: { staffId: staffId, users: target },
        });
      }
    }
  }
  handleCareprovider(event, staffUserId, staffId) {
    let optionType, careproviderId;
    this.setState({
      selectedOption : event
    });
    const difference1 = this.props.userAssignedCareprovider.filter(item => !event.includes(item));
    const difference2 = event.filter(item => !this.props.userAssignedCareprovider.includes(item));
    if(difference1.length > 0){
      optionType = 2;
      careproviderId = difference1.length > 1 ?  [] : difference1[0]._id;
    }
    if(difference2.length > 0){
      optionType = 1;
      careproviderId = difference2[0]._id;
    }
    this.props.dispatch({
      type: "DO_ADD_OR_UPDATE_MAINCAREPROVIDER_STAFF",
      payload: { staffId: staffUserId, maincareprovider: careproviderId, optionType: optionType},
    });
    setTimeout(() => {
      this.handleAllAssignedCareproviders(staffId);
    }, 1200);
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
    if (name === "confirmpassword") {
      const checkMatch = this.state.password === value;

      if (!checkMatch) {
        this.setState({ errorPassword: true });
      } else {
        this.setState({ errorPassword: false });
      }
    }
  }
  fetchData(){
    this.props.dispatch({
      type: "DO_GET_SHARED_ACCOUNT",
      payload: {
        page: this.state.page,
        limit: this.state.limit,
        sortField: this.state.sortField ? this.state.sortField : "",
        sortOrder: this.state.sortOrder ? this.state.sortOrder : "",
        search: this.state.search ? this.state.search : "",
      }
    });
  }
  resetForm() {
    this.setState({
      modal2: false,
      modal: false,
      modalStaff: false,
      fname: "",
      lname: "",
      nickname: "",
      username: "",
      email: "",
      phone: "",
      homeNumber: "",
      workNumber: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      weight: "",
      height: "",
      bday: "",
      memberId: "",
      organization: "",
      gender: "Male",
      role: "",
      otherRole: undefined
    });
    this.props.dispatch({
      type: "GENERIC_ERROR",
    });
    this.fetchData();
  }
  handleClearAllAssignedCareprovider(staffId){
    this.props.dispatch({
      type: "DO_REMOVE_ALL_ASSIGNED_CAREPROVIDER_STAFF",
      payload: { staffId: staffId },
    });
    setTimeout(() => {
      this.handleAllAssignedCareproviders(staffId);
    }, 1200);
  }
  toggleModal() {

    this.setState({
      modal: !this.state.modal,
    });
  }
  toggleStaff() {

    this.setState({
      modalStaff: !this.state.modalStaff,
    });
  }
  openInvite() {

    this.setState({
      modal2: !this.state.modal2,
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  deleteInvitation(id) {
    this.props.dispatch({
      type: "DO_DELETE_STAFF",
      payload: { id: id },
    });
    setTimeout(() => {
      this.fetchData();
    }, 500);
  }
  groupByUser() {
    var result = _.chain(this.props.pmRecords)
      .groupBy("patient._id")
      .map((trackTime, userId) => ({ trackTime, userId }))
      .value();
    return result;
  }
  handleRole(e){
    if(e.target.name != 'otherRole'){
      this.setState({ role: e.target.value, otherRole: undefined });
      return;
    }
    if(this.state.role == 'Other' && e.target.name == 'otherRole'){
      this.setState({ otherRole: e.target.value });
    }else if(this.state.role != 'Other' && e.target.name != 'otherRole'){
      this.setState({ otherRole: undefined });
    }
  }
  submit(e) {
    e.preventDefault();
    this.props.dispatch({
      type: "DO_SEND_INVITE",
      payload: {
        fname: this.state.fname,
        lname: this.state.lname,
        email: this.state.email,
        role: this.state.role != 'Other' ? this.state.role : this.state.otherRole,
        isOtherRole: this.state.role != 'Other' ? false : true,
        isStaff: true,
        isCareProvider: false,
      },
    });
  }
  submitStaff(e) {
    e.preventDefault();
    this.setState({
      role: this.state.role != 'Other' ? this.state.role : this.state.otherRole,
      isOtherRole: this.state.role != 'Other' ? false : true,
    }, () => {
      const cleanStaff = {
        address: this.state.address,
        bday: this.state.bday,
        city: this.state.city,
        email: this.state.email,
        fname: this.state.fname,
        gender: this.state.gender,
        height: this.state.height,
        homeNumber: this.state.homeNumber,
        isOtherRole: this.state.isOtherRole,
        lname: this.state.lname,
        memberId: this.state.memberId,
        nickname: this.state.nickname,
        organization: this.state.organization,
        password: this.state.password,
        phone: this.state.phone,
        role: this.state.role,
        state: this.state.state,
        username: this.state.username,
        weight: this.state.weight,
        workNumber: this.state.workNumber,
        zip: this.state.zip,
      }
      this.props.dispatch({
        type: "DO_SAVE_SHARED",
        payload: cleanStaff,
      });
      setTimeout(() => {
        this.fetchData();
        this.setState({
          modal2: false,
          modal: false,
          modalStaff: false,
          fname: "",
          lname: "",
          email: "",
          username: "",
          role: ""
        });
      }, 400);
    });
  }
  componentDidMount() {
    this.props.dispatch({
      type: "DO_GET_UNREAD_COUNT"
    });
    this.props.dispatch({
      type: "GENERIC_ERROR",
    });
    if (!this.props.isClient) {
      this.fetchData();
      this.props.dispatch({
        type: "DO_GET_MY_RULES",
      });
    }
  }

  render() {   
    if (this.props.patientFilePath) {
      if (this.state.link === undefined) {
        this.setState({
          link: this.props.patientFilePath,
        }, () => {
          this.props.dispatch({
            type: "DO_REMOVE_PATIENT_LINK_PATH",
            payload: undefined
          });
          window.open(this.state.link, "_self");
        }
        );
      }
    } else {
      this.state.link = undefined;
    } 
    const onlyActivePatients = _.orderBy(
      _.filter(this.props.allStaffPatientList, "isActive"),
      "fname",
      "asc"
    );
    const roleOptions = USERS_ROLE.filter((item) => item.type == "Staff" && item.value == 'Shared/Family Account');
    return (
      <div>
        <div className="row">
          {this.props.isAll && (
            <Heading
              title="STAFF/SUBUSER LIST"
              description=""
              backbutton="d-none"
              mainbutton="d-none"
              toggleModal={this.toggleModal}
            >
              <span className="page-title-icon bg-gradient-danger   text-white mr-2">
                <i className="mdi mdi-account-convert" />
              </span>
            </Heading>
          )}
          {!this.props.isAll && (
            <Heading
              title={null}
              description=""
              backbutton="d-none"
              mainbutton="d-block"
              toggleModal={this.toggleModal}
            >
              <span className="page-title-icon bg-gradient-danger   text-white mr-2">
                <i className="mdi mdi-account-convert" />
              </span>
            </Heading>
          )}

          <div className="col-12 grid-margin">
            <div className="card">
              <Nav tabs className="justify-content-center">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Shared / Family Account
                  </NavLink>
                </NavItem>
              </Nav>
              <Row className={`text-center col-md-12 mx-auto float-right`}>
                <Col md={10} style={{ marginTop: 15 }}>
                  <this.MySearch />
                </Col>
                <Col md={2} style={{ marginTop: 10 }}>
                  <this.MyExportCSV>
                    Export CSV
                  </this.MyExportCSV>
                </Col>
              </Row>
              <div className="card-body project-tab pt-2">
                <Row>
                  <Col sm="12" className="grid-margin mt-4">
                    {this.props.ongoingSharedAccountListRequest && (
                      <SharedList
                        usersOfferedAccess={onlyActivePatients}
                        ongoingAssignedUserRequest={this.props.ongoingAssignedUserRequest}
                        loading={this.props.ongoingSharedAccountListRequest}
                        deleteInvitation={this.deleteInvitation}
                        sharedAccount={[]}
                        userInputChange={this.userInputChange}
                        expectationInputChange={this.expectationInputChange}
                        handleCareprovider={this.handleCareprovider}
                        additionalCareproviderList={this.props.additionalCareproviderList}
                        onTableChange={this.handleTableChange}
                        page={this.state.page}
                        sizePerPage={this.state.limit}
                        totalSize={0}
                        handleAllPatients={this.handleAllPatients}
                        handleAllAssignedCareproviders={this.handleAllAssignedCareproviders}
                        ongoingAssignedCareproviderRequest={this.props.ongoingAssignedCareproviderRequest}
                        userAssignedCareprovider={this.props.userAssignedCareprovider}
                        selectedOption={this.state.selectedOption}
                        handleClearAllAssignedCareprovider={this.handleClearAllAssignedCareprovider}
                      />
                    )}
                    {!this.props.ongoingSharedAccountListRequest && this.props.sharedAccount && (
                      <SharedList
                        usersOfferedAccess={onlyActivePatients}
                        ongoingAssignedUserRequest={this.props.ongoingAssignedUserRequest}
                        loading={this.props.ongoingSharedAccountListRequest}
                        deleteInvitation={this.deleteInvitation}
                        sharedAccount={this.props.sharedAccount}
                        userInputChange={this.userInputChange}
                        expectationInputChange={this.expectationInputChange}
                        handleCareprovider={this.handleCareprovider}
                        additionalCareproviderList={this.props.additionalCareproviderList}
                        onTableChange={this.handleTableChange}
                        page={this.state.page}
                        sizePerPage={this.state.limit}
                        totalSize={this.props.totalRecode}
                        handleAllPatients={this.handleAllPatients}
                        handleAllAssignedCareproviders={this.handleAllAssignedCareproviders}
                        ongoingAssignedCareproviderRequest={this.props.ongoingAssignedCareproviderRequest}
                        userAssignedCareprovider={this.props.userAssignedCareprovider}
                        selectedOption={this.state.selectedOption}
                        handleClearAllAssignedCareprovider={this.handleClearAllAssignedCareprovider}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>
            Create A New Shared / Family Account
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <h5 className="text-center" />
            <div className="row">
              <div className="col-12 mx-auto">
                <NavLink
                  className="text-center d-block"
                  onClick={this.toggleStaff}
                >
                  <img src={familyAccount} style={{ height: "180px" }} />
                  <span className="mt-2 menu-title font-weight-bold d-block text-dark">
                    CREATE A NEW SHARED ACCOUNT
                  </span>
                  <small>You will input everything</small>
                </NavLink>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal2}
          toggle={this.openInvite}
          className={this.props.className}
        >
          <ModalHeader toggle={this.openInvite}>
            Send an invite link
          </ModalHeader>
          <ModalBody style={{ backgroundColor: "white" }}>
            <Form onSubmit={this.submit}>
              <FormGroup>
                <Label for="exampleText">First Name</Label>
                <Input
                  type="text"
                  name="fname"
                  required={true}
                  onChange={this.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Last Name</Label>
                <Input
                  type="text"
                  name="lname"
                  required={true}
                  onChange={this.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleText">Role *</Label>
                <select
                  name="role"
                  className="form-control"
                  onChange={this.handleRole}
                  required
                >
                <option value="">
                  Select Role
                </option>
                {roleOptions.map((option) => (
                  <option value={option.value}>{option.role}</option>
                ))}
              </select>
              </FormGroup>
              {(this.state.role == 'Other' || this.state.otherRole != undefined) && (
              <FormGroup>
                <Label for="exampleText">
                Other Role *
                </Label>
                <Input
                  type="text"
                  required={this.state.role == 'Other' ? true : false}
                  name="otherRole"
                  onChange={this.handleRole}
                  onKeyDown={this.handleKeyDown}
                />
              </FormGroup>
             )}
              <FormGroup>
                <Label for="exampleText">Email</Label>
                <Input
                  type="email"
                  name="email"
                  required={true}
                  onChange={this.handleInputChange}
                  rows={10}
                />
              </FormGroup>
              <button type="submit" className="btn btn-block btn-info">
                Send
              </button>
            </Form>
          </ModalBody>
        </Modal>
        <CreateStaffModal
          modalStaff={this.state.modalStaff}
          toggleStaff={this.toggleStaff}
          errorPassword={this.state.errorPassword}
          submitStaff={this.submitStaff}
          handleInputChange={this.handleInputChange}
          handleRole={this.handleRole}
          role = {this.state.role}
          handleKeyDown = {this.handleKeyDown}
        />
        <SweetAlert
          success
          title="Success!"
          show={this.props.isGenericSuccess}
          onConfirm={this.resetForm}
          confirmBtnBsStyle="info"
        >
          {!this.state.bday && (
            <div>
              Submitted Successfully Invitation is Sent to {this.state.email}
            </div>
          )}
          {this.state.bday && <div>Staff Created Successfully</div>}
        </SweetAlert>
        <SweetAlert
          error
          title={this.props.genericErrorMessage.message}
          show={this.props.isGenericError}
          onConfirm={() => {
            this.props.dispatch({
              type: "GENERIC_ERROR",
            });
          }}
          confirmBtnBsStyle="danger"
          timeout={1000}
        >
          Error Code: {this.props.genericErrorMessage.error}
        </SweetAlert>
      </div>
    );
  }
}
const mapStateToProps = ({ careprovider, user }) => {
  const {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    readingSubTypes,
    pmRecords,
    sharedAccount,
    allStaffPatientList,
    ongoingSharedAccountListRequest,
    additionalCareproviderList,
    totalRecode,
    ongoingAssignedUserRequest,
    ongoingAssignedCareproviderRequest,
    userAssignedCareprovider,
    patientFilePath,
  } = careprovider;
  const userId = user.userDetails._id;

  return {
    isGenericSuccess,
    isGenericError,
    genericErrorMessage,
    readingSubTypes,
    pmRecords,
    userId,
    sharedAccount,
    allStaffPatientList,
    ongoingSharedAccountListRequest,
    additionalCareproviderList,
    totalRecode,
    ongoingAssignedUserRequest,
    ongoingAssignedCareproviderRequest,
    userAssignedCareprovider,
    patientFilePath,
  };
};

export default connect(mapStateToProps)(AdminSharedAccount);