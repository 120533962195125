import Link from "components/ExtendLink";
import moment from "moment";
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import doctorImage from "../../../files/doctor.png";
import staffImage from "../../../files/staff.png";
import IC_NOTES from "../../../files/ic_notes.png";
import { Col, Row } from "reactstrap";
const _ = require("lodash");

function fullname(cell, row) {
  let imageUser;
  let isPublic;
  if (cell && row.createdBy) {
    if (!row.createdBy.isStaff) {
      imageUser = (
        <p>
          <img src={doctorImage} className="mr-2" alt="image" />
          {row.createdBy.fname} {row.createdBy.lname}
        </p>
      );
    } else {
      imageUser = (
        <p>
          <img src={staffImage} className="mr-2" alt="image" />
          {row.createdBy.fname} {row.createdBy.lname}
        </p>
      );
    }
  } else {
    imageUser = "";
  }
  if (row.isPublic) {
    isPublic = (
      <div className="ml-5">
        <span class="badge badge-pill badge-warning">Public Note</span>
      </div>
    );
  }
  return (
    <div>
      {imageUser} {isPublic}
    </div>
  );
}
function formatDate(cell) {
  return (<p>{moment(cell).format("MMMM DD, YYYY hh:mm:ss A")}</p>);
}

function device(cell, row, index, formatExtraData) {
  return (
    <div className="text-center"><p>{cell ? cell.name :  " - "}</p></div>
  );
}
function reminderSent(cell){
  return (
    <div className="text-center"><p>{cell && cell.reminderDate ? moment(cell.reminderDate).format("MMMM DD, YYYY hh:mm A") : " - "}</p></div>
  );
}
const NoteListPrint = React.forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      className="pb-12"
      style={{ backgroundColor: "white" }}
    >
      {props.notesPatientDetails && (
        <div className="text-center col-md-12 mx-auto mt-5 margin-row-notes-print">
          <img
            src={`${process.env.REACT_APP_HOST_URL_PREFIX}/static/media/smarthealth.c3ca62dd.jpg`}
            alt="new"
            width="290px"
          />
          <Row className="text-center col-md-12 mx-auto mt-5 margin-row-notes-print" style={{ margin: 10,padding:0 }}>
            <Col className="notes-pdf-title">              
              <img
                src={IC_NOTES}
                width="50px"
                className="img-circle"
                alt
              />
              <br />
              <span>
                {" "}
                <br /> {"\u00a0\u00a0"} NOTES
              </span>
              <br />
            </Col>
            <Col className="notes-pdf-details">
              <table>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>Patient</td>
                    <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}
                      {props.notesPatientDetails ?
                        props.notesPatientDetails.fname + " " +
                        props.notesPatientDetails.lname : " - "}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>Nickname</td>
                    <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}
                      { props.notesPatientDetails && props.notesPatientDetails.nickname ? props.notesPatientDetails.nickname : " - "}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>Main Care Provider</td>
                    <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}
                      {props.notesPatientDetails && props.notesPatientDetails.mainCareProvider ?
                        props.notesPatientDetails.mainCareProvider.fname + " " +
                        props.notesPatientDetails.mainCareProvider.lname : " - "}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>Phone No </td>
                    <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}{props.notesPatientDetails.phone}</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>Last Reading Date</td>
                    <td style={{ fontWeight: 600, textAlign: "left" }}>:{"\u00a0\u00a0"}
                      {props.latestReadingUser && props.latestReadingUser.deviceReadingDate
                        ? moment(props.latestReadingUser.deviceReadingDate).format("MMMM DD, YYYY") : "NONE"}</td>
                  </tr>
                  <tr>
                        <td style={{textAlign:"left"}}>Total CCM Time </td>
                        <td style={{fontWeight:600,textAlign:"left"}}>:{"\u00a0\u00a0"}{props.ccmTotalDuration ?? '00:00:00'}</td>
                      </tr> 
                      <tr>
                        <td style={{textAlign:"left"}}>Total RPM Time </td>
                        <td style={{fontWeight:600,textAlign:"left"}}>:{"\u00a0\u00a0"}{props.rpmTotalDuration ?? '00:00:00'}</td>
                      </tr> 
                </tbody>
              </table>
            </Col>
          </Row>
          <BootstrapTable
            wrapperClasses="table-responsive"
            keyField="_id"
            data={_.orderBy(props.notes, "createdAt", "desc")}           
            columns={[
              {
                dataField: "subject",
                text: "Subject",
                sort: false,
                classes:"left-cell",
                headerStyle: (colum, colIndex) => {
                  return { width: '250px', textAlign: 'left' };
                },
                formatter: (cell) => (
                  <p>{cell ? cell : '-'}</p>
                ),
              },
              {
                dataField: "body",
                text: "Note",
                classes:"left-cell",
                sort: false,
                headerStyle: (colum, colIndex) => {
                  return { width: '350px', textAlign: 'left' };
                }
              },
              {
                dataField: "device",
                text: "Shipped Device",
                sort: true,
                formatter: device,  
                headerStyle: (colum, colIndex) => {
                  return { width: '180px', textAlign: 'left' };
                }      
              },
              {
                dataField: "createdAt",
                text: "Time",
                classes:"left-cell",
                formatter: formatDate,
                sort: false,
                headerStyle: (colum, colIndex) => {
                  return { width: '200px', textAlign: 'left' };
                },
              },
              {
                dataField: "reminderNoteData",
                text: "Reminder Date",
                sort: false,
                formatter: reminderSent,  
                headerStyle: (colum, colIndex) => {
                  return { width: '185px', textAlign: 'left' };
                }      
              },
              {
                dataField: "createdBy.lname",
                text: "Noted By",
                sort: false,
                classes:"left-cell",
                formatter: fullname,
                headerStyle: (colum, colIndex) => {
                  return { width: '180px', textAlign: 'left' };
                }
              }
            ]}
          />
        </div>
      )}

    </div>
  );
});

export default NoteListPrint;
