import {
  priceFormatter,
  withIcon,
  formatDate,
  fullname,
  customTotal,
  vitalReading,
  nickname,
  MyExportCSV,
} from "./violationHelpers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "../../../css/css/style.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Col, Row } from "reactstrap";
import LottieLoader from "../../LottieLoader";
import lottieEmptyFile from "../../../files/animation/empty_new.json";
import lottieLoaderFile from "../../../files/animation/loading.json";
import { _ } from "lodash";
import ViolationFilters from "./ViolationFilters";

const NewViolationList = (props, context) => {
  const options = {
    page: props.page,
    sizePerPage: props.sizePerPage,
    totalSize: props.totalSize,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    // hideSizePerPage: props.displayNeedToFollowUpList ? true : parseInt(props.totalSize) == 0 ? true : false, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: parseInt(props.totalSize) == 0 ? false : true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      {
        text: "25",
        value: 25,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "200",
        value: 200,
      },
    ],
  };

  const NoDataIndication = () => (
    <div>
      {!props.loading && (
        <LottieLoader
          loadLoop={false}
          autoplayLoop={true}
          source={lottieEmptyFile}
          Lheight={300}
          Lwidth={300}
          title={"Sorry! No " + props.activeCard + " found :("}
          subTitle={"We can't find any " + props.activeCard + "."}
        />
      )}
    </div>
  );

  return (
    <div>
      <ToolkitProvider
        keyField="_id"
        data={props.usersViolations}
        columns={[
          {
            dataField: "name",
            text: "Title",
            sort: true,
            headerFormatter: priceFormatter,
            formatter: (cell, row) => {
              if (row && row.isRead) {
                return row.name;
              } else {
                if (row) {
                  return <strong>{row.name}</strong>;
                } else {
                  return <strong></strong>;
                }
              }
            },
            headerStyle: {
              width: "250px", // Set your desired width
            },
            style: {
              width: "250px", // Set your desired width
            },
          },         
          ...(props.display !== "Questionnaires"
            ? [
                {
                  dataField: "readingSubType",
                  text: "Reading Sub Type",
                  sort: true,
                  headerFormatter: priceFormatter,
                  formatter: (cell, row) => {
                    if(cell){
                      return cell
                    }else{
                      return '-'
                    }
                  },
                  headerStyle: {
                    width: "250px", // Set your desired width
                  },
                  style: {
                    width: "250px", // Set your desired width
                  },
                },
              ]
            : []),
          ...(props.display !== "Questionnaires"
            ? [
                {
                  dataField: "ruleType",
                  text: "Rule Type",
                  sort: true,
                  formatter: withIcon,
                  headerFormatter: priceFormatter,
                  headerStyle: {
                    width: "250px", // Set your desired width
                  },
                  style: {
                    width: "250px", // Set your desired width
                  },
                },
              ]
            : []),
          {
            dataField: "violatedBy",
            text: "Patient",
            sort: true,
            formatter: fullname,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row) => {
              return (
                cell.fname +
                " " +
                cell.lname +
                (cell.mainCareProvider
                  ? "(" +
                    cell.mainCareProvider.fname +
                    " " +
                    cell.mainCareProvider.lname +
                    ")"
                  : "")
              );
            },
            headerStyle: {
              width: "250px", // Set your desired width
            },
            style: {
              width: "250px", // Set your desired width
            },
            csvExport: true,
          },
          {
            dataField: "violatedBy.nickname",
            text: "Patient Nickname",
            sort: true,
            formatter: nickname,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row) => {
              return row.violatedBy.nickname ? row.violatedBy.nickname : "-";
            },
            headerStyle: {
              width: "190px", // Set your desired width
            },
            style: {
              width: "190px", // Set your desired width
            },
          },
          {
            dataField: "createdAt",
            text: "Occurred",
            sort: true,
            formatter: formatDate,
            headerFormatter: priceFormatter,
            csvFormatter: (cell, row) => {
              return moment(cell).format("MMMM DD, YYYY hh:mm A");
            },
            headerStyle: {
              width: "150px", // Set your desired width
            },
            style: {
              width: "150px", // Set your desired width
            },
          },
          ...(props.display !== "Adherences"
            ? [{
            dataField: "reading",
            text: "Details",
            formatter: vitalReading,
            headerFormatter: priceFormatter,
            csvExport: false,
            headerStyle: {
              width: "150px", // Set your desired width
            },
            style: {
              width: "150px", // Set your desired width
            },
          }] : []),
        ]}
        exportCSV={{
          fileName: `${props.activeCard}.csv`,
          onlyExportFiltered: true, // Ensures only filtered data is exported
          exportAll: false, // Disables exporting all data by default
        }}
      >
        {(toolKitProp) => (
          <div>
            <ViolationFilters
              handleFilterChange={props.handleFilterChange}
              filterData={props.filterData}
              searchQuery={props.searchQuery}
              setSearchQuery={props.setSearchQuery}
              handleFilterViaRuleType={props.handleFilterViaRuleType}
              totalSize={props.totalSize}
              handleFilterViaDate={props.handleFilterViaDate}
              loading={props.loading}
              ruleType={props.ruleType}
              readingSubType={props.readingSubType}
              handleRuleTypeChange={props.handleRuleTypeChange}
              handleReadingTypeChange={props.handleReadingTypeChange}
              handleSearch={props.handleSearch}
              search={props.search}
              setSearch={props.setSearch}
              datesForException={props.datesForException}
              isCareProvider={props.isCareProvider}
              setActiveCard={props.setActiveCard}
              isAdminPatient={props.isAdminPatient}
              activeCard={props.activeCard}
              isClientPatient={props.isClientPatient}
              patientId={props.patientId}
              setDisplay={props.setDisplay} 
              display={props.display}
              isCareProviderPatient={props.isCareProviderPatient}
              user={props.user}
            />
            {!props.loading && (
              <BootstrapTable
                remote={{
                  sort: false,
                  pagination: true,
                  filter: true,
                  search: false,
                }}
                wrapperClasses="table-responsive"
                {...toolKitProp.baseProps}
                noDataIndication={() => <NoDataIndication />}
                hover={props.totalSize > 0 ? true : false}
                onTableChange={props.onTableChange}
                loading={props.loading}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
              />
            )}
            {props.loading && (
              <LottieLoader
                loadLoop={true}
                autoplayLoop={true}
                source={lottieLoaderFile}
                Lheight={150}
                Lwidth={150}
                subTitle={"Please Wait ..."}
              />
            )}
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
};

export default NewViolationList;
